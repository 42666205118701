import React, { PureComponent, Fragment } from 'react';
import { Button } from 'antd';
import { SectionHeading, SectionFooter, SectionBody } from '../shared';
import Item from '../Items/SelectorItem';
import Filter from '../Filter';
import { convertFiltersToQueryObj } from '../../modules/utils/metadata';
import container from '../../containers/blockSelectorContainer';

//  COMPONENTS FOR: Block Selector
const BlockSelectorHeading = ({ count, handleClick }) => (
  <SectionHeading title="Select A Block">
    <Button onClick={handleClick} size="small">
      Filter {count}
    </Button>
  </SectionHeading>
);

class BlockSelector extends PureComponent {
  constructor(props) {
    super(props);
    const { filters, filterTitles } = props.getFilters();
    this.state = {
      showFilters: false,
      filters,
      filtersCount: 0,
      blockLabelOptions: undefined,
      totalOptions: 0,
      prevFilter: { ...filters },
    };
    this.filterTitles = filterTitles;
  }

  async componentWillMount() {
    const { getBlockLabels } = this.props;
    const labelOptions = await getBlockLabels();
    this.setState({
      blockLabelOptions: labelOptions,
      totalOptions: labelOptions.length,
    });
  }

  async componentWillReceiveProps(newProps) {
    const { section, getBlockLabels, validation, setValidation } = newProps;
    if (section === 'block' && this.props.section !== section && validation) {
      const labelOptions = await getBlockLabels();
      this.setState({
        blockLabelOptions: labelOptions,
        totalOptions: labelOptions.length,
      });
      setValidation(false);
    }
  }

  handleToggleFilters = async () => {
    const { showFilters, filters } = this.state;
    const { filterBlockLabels, getBlockLabels } = this.props;
    const newState = {};
    if (showFilters) {
      const query = convertFiltersToQueryObj(filters);
      const count = Object.values(query).reduce(
        (count, queryValue) => count + queryValue.length,
        0
      );
      newState.filtersCount = count;
      newState.blockLabelOptions = filterBlockLabels(
        query,
        await getBlockLabels()
      );
    }
    newState.showFilters = !showFilters;
    newState.prevFilter = filters;
    this.setState(newState);
  };

  isFilterChanged = () => {
    const { filters, prevFilter } = this.state;
    for (let field in filters) {
      for (let value in filters[field]) {
        if (filters[field][value] !== prevFilter[field][value]) return true;
      }
    }
    return false;
  };

  handlePreselectBlockLabel = (name) =>
    this.props.setBlockLabel(name === this.props.blockLabel ? null : name);

  handleApplyFilters = (filters) => this.setState({ filters });

  handleClearFilters = () =>
    this.setState({ filters: this.props.getFilters().filters });

  render() {
    const { handleContinue, handleBack, blockLabel } = this.props;
    const {
      filtersCount,
      blockLabelOptions,
      showFilters,
      filters,
      totalOptions,
    } = this.state;
    return (
      <Fragment>
        <BlockSelectorHeading
          handleClick={this.handleToggleFilters}
          count={filtersCount > 0 && filtersCount}
        />
        <SectionBody withWrapper>
          {blockLabelOptions &&
            blockLabelOptions.map((blockLabelOption) => (
              <Item
                key={blockLabelOption.productLabel}
                {...blockLabelOption}
                onSelect={this.handlePreselectBlockLabel}
                selected={blockLabelOption.productLabel === blockLabel}
                disabled={!blockLabelOption.instruments}
              />
            ))}
        </SectionBody>

        <SectionFooter
          continueDisabled={!blockLabel}
          handleContinue={handleContinue}
          continueLabel={'Confirm Selection'}
          handleBack={handleBack}
        />

        <Filter
          show={showFilters}
          items="Blocks"
          total={totalOptions}
          handleCloseFilter={this.handleToggleFilters}
          filters={filters}
          setFilters={this.handleApplyFilters}
          clearFilters={this.handleClearFilters}
          disableApplyFilter={!this.isFilterChanged()}
          filterTitles={this.filterTitles}
        />
      </Fragment>
    );
  }
}

export default container(BlockSelector);
