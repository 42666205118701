import React from 'react';
import {
  Wrapper,
  Primary,
  Secondary,
  SecondaryText,
  Image,
  Buttons,
  ItemBtn,
} from './items.styles';
import { CenterVert } from '../shared';
import container from '../../containers/instrumentItemContainer';
import { Icon, Input, Tooltip } from 'antd';
import iconNoImage from '../../assets/icons/no-image.webp';

export const StepItem = ({
  //  props from parent
  idx,
  thumbnailUrl,
  modelId,
  label,
  productLabel,
  handleExpand,
  expandedList,
  inHold,
  etchingLabel,
  //  props from container
  setInstruments,
  deleteInstrument,
  getEtchingLabelMssg,
  productNumber,
}) => {
  const isExpanded = expandedList.has(idx);

  const handleClickExpand = (e) => {
    e.stopPropagation();
    handleExpand(idx);
  };

  const handleClickDelete = () => deleteInstrument(modelId);

  const handleUpdateEtchingLabel = (e) =>
    setInstruments({ modelId, etchingLabel: e.currentTarget.value });

  return (
    <Wrapper
      expand={isExpanded}
      size={thumbnailUrl ? 'large' : 'small'}
      inHold={inHold}
    >
      <Primary size={thumbnailUrl ? 'large' : 'small'}>
        <div>
          {thumbnailUrl && (
            <Image expand={isExpanded}>
              <img
                src={thumbnailUrl}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = iconNoImage;
                }}
                alt="item"
              />
            </Image>
          )}
        </div>
        <CenterVert>{label || productLabel}</CenterVert>
        <CenterVert>
          <Buttons>
            <ItemBtn onClick={handleClickDelete}>
              <Icon type="delete" />
            </ItemBtn>
            <ItemBtn onClick={handleClickExpand} expand={isExpanded}>
              <Icon type="down" />
            </ItemBtn>
          </Buttons>
        </CenterVert>
      </Primary>
      {isExpanded && (
        <Secondary>
          <div>
            Enter a custom label{' '}
            <Tooltip title={getEtchingLabelMssg()}>
              <Input
                type="text"
                value={etchingLabel}
                onChange={handleUpdateEtchingLabel}
              />
            </Tooltip>
          </div>
          <SecondaryText>{productNumber}</SecondaryText>
        </Secondary>
      )}
    </Wrapper>
  );
};

export default container(StepItem);
