import React from 'react';
import { connect } from 'react-redux';
import { actions, selectors } from '../modules';

export default Component => {
  const mapDispatchToProps = {
    //  selectors
    getProcedures: selectors.metadata.getProcedures,
    //  actions
    setProcedure: actions.ui.setProcedure,
  };

  const mapStateToProps = ({ ui }) => ({
    selectedProcedure: ui.selectedProcedure,
  });

  const WrappedComponent = props => <Component {...props} />;

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent);
};
