import {
  filterFromQuery,
  buildRecommendedProductList,
} from '../utils/metadata';
import { collapseHolesCount } from '../utils/config';
import { UI_FLOW } from '../../constants';

function getCatelogDataCreater(dataType, tableName) {
  return (query, optionalDatas) => (_, getState) => {
    const state = getState();
    const targetData = state.metadata[dataType];
    if (!targetData) return [];
    const datas = optionalDatas || Object.values(targetData.data[tableName]);
    return filterFromQuery(query || {}, datas);
  };
}

const getBlocks = getCatelogDataCreater('productData', 'Blocks');
const getBlockLabels = getCatelogDataCreater('productData', 'BlockLabels');
const getInstruments = getCatelogDataCreater('productData', 'Instruments');

const getProcedures = getCatelogDataCreater('procedureData', 'Procedures');
const getProcedureSteps = getCatelogDataCreater(
  'procedureData',
  'ProcedureSteps'
);

function getPropertyDataCreater(dataType) {
  return (tableName) => (_, getState) => {
    const state = getState();
    const targetData = state.metadata[dataType].propertyData;
    return targetData[tableName];
  };
}

const getProductSheetPropertyData = getPropertyDataCreater('productData');

const getProcedureStepsByProcedure = (procedureName) => (_, getState) => {
  const { ui, metadata } = getState();
  if (!ui.selectedProcedure || !metadata.procedureData) return [];
  const procedure = procedureName || ui.selectedProcedure;
  const stepList = metadata.procedureData.data.Procedures[procedure].steps;

  return stepList.map(
    (step) => metadata.procedureData.data.ProcedureSteps[step]
  );
};

function getAttributeValuesCreater(tableName) {
  return (attribute, optionalDatas) => (_, getState) => {
    const state = getState();
    const { productData } = state.metadata;
    if (!productData) return [];
    const datas = optionalDatas || Object.values(productData.data[tableName]);

    const valueMap = {};
    datas.forEach((data) => {
      const value = data[attribute];
      if (value !== undefined && !valueMap[value]) valueMap[value] = true;
    });
    return Object.keys(valueMap);
  };
}

const getBlockAttributeValues = getAttributeValuesCreater('Blocks');
const getInstrumentAttributeValues = getAttributeValuesCreater('Instruments');

const getBlocksByProductLabel = (productLabel) => (_, getState) => {
  const state = getState();
  const { productData } = state.metadata;
  const { country } = state.app;
  const countryKey = `countryActive-${country}`;
  if (!productData) return [];
  const { BlockLabels, Blocks } = productData.data;
  return BlockLabels[productLabel].blocks
    .map((productName) => Blocks[productName])
    .filter(
      (block) =>
        block.active && (!block.hasOwnProperty(countryKey) || block[countryKey])
    );
};

function getCatelogDataByKeyCreater(dataType, tableName) {
  return (uniqueKeys) => (_, getState) => {
    const state = getState();
    const targetData = state.metadata[dataType];
    if (!targetData) return;

    if (typeof uniqueKeys === 'string')
      return targetData.data[tableName][uniqueKeys];

    if (Array.isArray(uniqueKeys)) {
      return uniqueKeys.map((key) => targetData.data[tableName][key]);
    }
  };
}

const getBlockByProductNumber = getCatelogDataByKeyCreater(
  'productData',
  'Blocks'
);
const getInstrumentByProductNumber = getCatelogDataByKeyCreater(
  'productData',
  'Instruments'
);
const getBlockLabelByLabel = getCatelogDataByKeyCreater(
  'productData',
  'BlockLabels'
);
const getProcedureByName = getCatelogDataByKeyCreater(
  'procedureData',
  'Procedures'
);
const getProcedureStepByName = getCatelogDataByKeyCreater(
  'procedureData',
  'ProcedureSteps'
);
const getUIElementByName = getCatelogDataByKeyCreater('productData', 'UI');
const getSettingByName = getCatelogDataByKeyCreater('productData', 'Settings');

const getBlockLabelsByProcedure = (procedureName) => (dispatch, getState) => {
  const { ui } = getState();

  //  If a procedureName is provided we use that
  //  otherwise we check the ui state for a selected procedure
  const procedure = procedureName || ui.selectedProcedure;
  if (!procedure) return [];

  //  We then extract an array of productNumbers for both
  //  recommendedBlockLabels and blocks
  const { recommendedBlocks: recommendedList, blocks: blocksList } = dispatch(
    getProcedureByName(procedure)
  );

  //  If there are no blocks for the procedure we return
  if (!blocksList) return [];
  const blocks = dispatch(getBlockLabelByLabel(blocksList));
  const recommendedBlocks = dispatch(getBlockLabelByLabel(recommendedList));

  return buildRecommendedProductList(blocks, recommendedBlocks);
};

const getInstrumentsByBlock = (optionalBlock) => (dispatch, getState) => {
  const { config } = getState();
  const { productNumber, holeData } = config.block;
  const block = optionalBlock || productNumber;
  const {
    minInstrumentLength,
    maxInstrumentLength,
    holesCount,
    instrumentHeightOffset,
  } = dispatch(getBlockByProductNumber(block));

  const shank = Object.keys(
    optionalBlock ? collapseHolesCount(holesCount) : holeData
  ).reduce(
    (shankValue, shankName) => shankValue.concat(shankName.split('/')),
    []
  );
  const length = {
    type: 'range',
    value: {
      min: minInstrumentLength - instrumentHeightOffset,
      max: maxInstrumentLength - instrumentHeightOffset,
    },
  };
  // build query object to filter instruments based on block hole type and height
  const query = { shank, length };
  return dispatch(getInstruments(query));
};

const getInstrumentsByProcedureStep = (stepName) => (dispatch, getState) => {
  const { ui } = getState();

  //  If a stepName is provided we use that
  //  otherwise we check the ui state for a selected step
  const step = stepName || ui.selectedStep;
  if (!step) return [];

  //  We then extract an array of productNumbers for both
  //  recommendedInstruments and instruments
  const {
    recommendedInstruments: recommendedList,
    instruments: instrumentsList,
  } = dispatch(getProcedureStepByName(step));

  //  If there are no instruments for that step we return
  if (!instrumentsList) return [];

  //  We use our array of instrument productNumbers
  //  to get an array of instruments
  const instruments = dispatch(getInstrumentByProductNumber(instrumentsList));
  const recommendedInstruments = dispatch(
    getInstrumentByProductNumber(recommendedList)
  );
  //  Finally we tag the instrument as recommended if
  //  the productNumber is also in the recommendedList
  return buildRecommendedProductList(instruments, recommendedInstruments);
};

const getInstrumentsFromConfigState = () => (dispatch, getState) => {
  const { ui, app } = getState();
  const { flow, selectedStep } = ui;
  const { country } = app;
  const countryKey = `countryActive-${country}`;
  return dispatch(
    flow === UI_FLOW.procedure
      ? getInstrumentsByProcedureStep(selectedStep)
      : getInstrumentsByBlock()
  ).filter(
    (instrument) =>
      instrument.active &&
      (!instrument.hasOwnProperty(countryKey) || instrument[countryKey])
  );
};

const getBlockLabelsFromConfigState = () => (dispatch, getState) => {
  const { ui, app } = getState();
  const { flow, selectedProcedure } = ui;
  const { country } = app;
  const countryKey = `countryActive-${country}`;
  return dispatch(
    flow === UI_FLOW.procedure
      ? getBlockLabelsByProcedure(selectedProcedure)
      : getBlockLabels()
  ).filter(
    (block) =>
      block.active && (!block.hasOwnProperty(countryKey) || block[countryKey])
  );
};

export default {
  getBlocks,
  getBlockLabels,
  getInstruments,
  getProcedures,
  getProcedureSteps,
  getBlockAttributeValues,
  getInstrumentAttributeValues,
  getBlocksByProductLabel,
  getBlockLabelByLabel,
  getBlockByProductNumber,
  getInstrumentByProductNumber,
  getProcedureByName,
  getProcedureStepByName,
  getInstrumentsFromConfigState,
  getProcedureStepsByProcedure,
  getBlockLabelsByProcedure,
  getBlockLabelsFromConfigState,
  getUIElementByName,
  getSettingByName,
  getProductSheetPropertyData,
};
