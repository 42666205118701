import { SET_INIT_STATE } from './utils/actionType';

export const initialState = {
  threekitApi: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_INIT_STATE:
      return { ...state, ...(payload.threekit || {}) };
    default:
      return state;
  }
};
