import {
  highlightInstrument,
  getTopDecalMetadata,
  getInstrumentDecalMetadata,
  frameScene,
} from '../utils/config';
import { APP_PRESET_API, APP_URL, COUNTRY_DATA } from '../../constants';

const getProductList = () => (_, getState) => {
  const state = getState();
  const { block, instruments } = state.config;
  const productInstruments = Object.values(instruments).reduce(
    (count, { productNumber }) => {
      if (!count[productNumber]) count[productNumber] = 1;
      else count[productNumber] += 1;
      return count;
    },
    {}
  );
  const productList = Object.entries(productInstruments).map(([id, qty]) => ({
    id,
    qty,
  }));
  if (block.productNumber)
    productList.push({ id: block.productNumber, qty: 1 });
  return productList;
};

const getConfiguration = () => async (_, getState) => {
  const state = getState();
  const {
    block,
    instruments,
    silhouetteVisible,
    logo,
    blockName,
  } = state.config;
  const { selectedProcedure } = state.ui;
  const { country, year } = state.app;
  const { threekitApi } = state.threekit;
  const { productData } = state.metadata;
  const { productNumber, lidLabel, lidLogo, fileId, blockData } = block;
  const { productLabel, color } = productData.data.Blocks[productNumber];
  return {
    block: { productNumber, lidLabel, lidLogo, fileId },
    lidMetadata: await getTopDecalMetadata(threekitApi, blockData),
    instruments: await Promise.all(
      Object.values(instruments).map(async (instrument) =>
        Object.assign(
          await getInstrumentDecalMetadata(threekitApi, instrument),
          {
            productNumber: instrument.productNumber,
            etchingLabel: instrument.etchingLabel,
            holeShank: instrument.holeShank,
            holeIdx: instrument.holeIdx,
            step: instrument.step,
            silhouetteVisible,
          }
        )
      )
    ),
    selectedProcedure,
    country,
    year,
    blockStyle: productLabel,
    blockColor: color,
    logoOriginalName: lidLogo && logo.name,
    blockName,
  };
};

const getSnapshot = (options) => async (_, getState) => {
  const { threekit, config } = getState();
  const { threekitApi } = threekit;
  const { selectedModel } = config;
  await highlightInstrument(threekitApi);
  const activeCameraId = threekitApi.player.cameraController.getActiveCamera();
  const snapshotCameraId = threekitApi.scene.findNode({
    name: 'Snapshot_Camera',
  });
  threekitApi.player.cameraController.setActiveCamera(snapshotCameraId);
  threekitApi.player.cameraController.frameBoundingSphere(
    undefined,
    undefined,
    { useCameraAspectRatio: true }
  );
  const snapshot = threekitApi.snapshot(options);
  threekitApi.player.cameraController.setActiveCamera(activeCameraId);
  frameScene(threekitApi);
  selectedModel && highlightInstrument(threekitApi, selectedModel);
  return snapshot;
};

const getPresetEndpoint = () => () => {
  return APP_PRESET_API;
};

const getConfiguratorResumeLink = () => (_, getState) => {
  const { ui, order } = getState();
  const { uuid } = order;
  const { flow } = ui;
  const resumeLink = `${APP_URL}/${flow}/${uuid}`;
  return { resumeLink };
};

const getShareLink = () => (_, getState) => {
  const { app, order } = getState();
  const { env, country } = app;
  const { uuid } = order;
  const endpoint =
    env === 'production'
      ? COUNTRY_DATA[country].prodShareLink
      : COUNTRY_DATA[country].stagingShareLink;
  return `${endpoint}/kitview/?uuid=${uuid}&country=${country}`;
};

const getArtfileLink = (uuid) => () => {
  return `${APP_URL}/api/logo/${uuid}`;
};

export default {
  getProductList,
  getConfiguration,
  getSnapshot,
  getPresetEndpoint,
  getConfiguratorResumeLink,
  getShareLink,
  getArtfileLink,
};
