import styled from 'styled-components';

export const PlayerContainer = styled.div`
  width: 100%;
  height:100%
  overflow: hidden;

  & > div {
    height: 100%;
  }
`;
