import React, { useState } from 'react';
import StepSelector from './StepSelector';
import ProcedureSelector from './ProcedureSelector';
import Instrument from '../InstrumentSection/InstrumentSelector';
import instrumentContainer from '../../containers/instrumentSelectorContainer';
import { SectionWrapper, SubsetionWrapper } from '../shared';
import container from '../../containers/procedureSectionContainer';

const InstrumentSelector = instrumentContainer(Instrument);
const subsections = {
  procedures: 'procedures',
  steps: 'steps',
  instruments: 'instruments',
};

export default container(({ handleContinue, height }) => {
  const [subsection, setSubsection] = useState(subsections.procedures);
  return (
    <SectionWrapper height={height}>
      <SubsetionWrapper show>
        <ProcedureSelector
          handleSelectProcedure={() => setSubsection(subsections.steps)}
        />
      </SubsetionWrapper>
      <SubsetionWrapper
        show={
          subsection === subsections.steps ||
          subsection === subsections.instruments
        }
        index={1}
      >
        <StepSelector
          handleSelectStep={() => setSubsection(subsections.instruments)}
          handleChangeProcedure={() => setSubsection(subsections.procedures)}
        />
      </SubsetionWrapper>
      <SubsetionWrapper show={subsection === subsections.instruments} index={2}>
        {subsection === subsections.instruments && (
          <InstrumentSelector
            handleBack={() => setSubsection(subsections.steps)}
            handleContinue={() => setSubsection(subsections.steps)}
            handleNextSection={handleContinue}
          />
        )}
      </SubsetionWrapper>
    </SectionWrapper>
  );
});
