import React, { useState, useEffect } from 'react';
import { Upload, Icon, message, Input } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';

import {
  SectionHeading,
  SectionBody,
  SectionFooter,
  SectionWrapper,
  AddItemButton,
} from '../shared';
import { Buttons } from '../Items/items.styles';
import {
  CustomizeTitle as Title,
  Section,
  Textarea,
  Characters,
  UploadInfo,
  CustomizeItemBtn as ItemBtn,
} from './customize.styles';
import container from '../../containers/customizeSectionContainer';
import { UI_FLOW, UI_SECTIONS } from '../../constants';
import {
  uploadImageToPlatform,
  getAssetId,
} from '../../modules/utils/threekit';
import { getImageAspectRatio } from '../../modules/utils/config';

const { Dragger } = Upload;

const CustomizeSection = ({
  flow,
  height,
  configBlock,
  configLogo,
  setSection,
  handleBack,
  handleContinue,
  setBlock,
  setLogo,
  getLogoEndpoint,
  lidLabel,
  getUIElementByName,
  blockName,
  setBlockName,
}) => {
  const [uploading, setUploading] = useState(false);
  const [imageSrc, setImageSrc] = useState(
    configLogo && URL.createObjectURL(configLogo.originFileObj)
  );
  useEffect(
    () => {
      configLogo && setImageSrc(URL.createObjectURL(configLogo.originFileObj));
    },
    [configLogo]
  );
  const belowTextboxHtml = getUIElementByName('belowLidTextbox');
  const uploaderData = getUIElementByName('imageUploader');
  const handleLabelChange = (e) => {
    const text = e.currentTarget.value.substr(0, blockData.lidLabelTextLimit);
    setBlock({ lidLabel: text });
  };
  const handleLogoDelete = () => {
    setBlock({ lidLogo: null });
    setLogo(undefined);
  };

  const blockData = configBlock.blockData;
  const uploadHandler = {
    name: 'logo',
    multiple: false,
    action: getLogoEndpoint(),
    showUploadList: false,
    disabled: uploading,
    beforeUpload(file, fileList) {
      if (!file.name.endsWith('.svg')) {
        message.error(`Invalid image file, only svg file are supported.`, 7);
        return false;
      }
      setUploading(true);
      return true;
    },
    onChange: async (info) => {
      const { status } = info.file;
      try {
        if (status === 'done') {
          const modifiedSvgName = `${
            info.file.name.split('.svg')[0]
          }_White.svg`;
          message.success(`${modifiedSvgName} upload job started.`, 7);
          const uploadRes = await uploadImageToPlatform(
            new Blob([info.file.response], {
              type: 'image/svg+xml;charset=utf-8',
            }),
            modifiedSvgName
          );
          const { job, jobId } = uploadRes[0];

          if (!job || !jobId) {
            message.error(
              `Error occurred adding job for ${modifiedSvgName}`,
              7
            );
            throw new Error();
          }
          const params = JSON.parse(job.tasks[0].parameters);
          const assetId = await getAssetId(jobId);
          if (!assetId) throw new Error('Upload asset job exceeded time limit');

          message.success(`${modifiedSvgName} file uploaded successfully.`, 7);
          setUploading(false);
          setLogo(info.file);
          const logoAspectRatio = await getImageAspectRatio(
            info.file.originFileObj
          );
          await setBlock(
            { lidLogo: assetId, ...params },
            {
              configuration: {
                'Lid Logo Aspect Ratio': logoAspectRatio,
              },
            }
          );
        } else if (status === 'error') {
          message.error(`${info.file.name} svg modification failed.`, 7);
          throw new Error();
        }
      } catch (e) {
        setUploading(false);
      }
    },
  };

  if (!blockData)
    return (
      <SectionWrapper>
        <SectionHeading
          title={
            flow === UI_FLOW.procedure
              ? 'Procedure is required to Customize!'
              : 'Block is required to Customize!'
          }
        />
        <AddItemButton
          onClick={() => setSection(UI_SECTIONS[UI_FLOW[flow]][0])}
          label={
            flow === UI_FLOW.procedure ? 'Select a Procedure' : 'Add a Block'
          }
        />
      </SectionWrapper>
    );
  return (
    <SectionWrapper height={height}>
      <SectionHeading title="Customize" />
      <SectionBody>
        <Title>SmartKit Name</Title>
        <Section>
          <div>For online reference only, will not etch on block.</div>
          <Input
            placeholder={'Enter SmartKit name here.'}
            value={blockName}
            onChange={(e) => {
              console.log(e.target.value);
              setBlockName(e.target.value);
            }}
          />
        </Section>
        <Title>Block Lid</Title>
        {blockData.lidLogoPosition !== 'None' && (
          <Section>
            <div>Upload a logo to be displayed on top of block's lid.</div>
            <div>
              <Dragger {...uploadHandler}>
                <p className="ant-upload-drag-icon">
                  {uploading ? <UploadOutlined /> : <InboxOutlined />}
                </p>
                <div dangerouslySetInnerHTML={{ __html: uploaderData.label }} />
              </Dragger>
            </div>
            {configLogo && (
              <UploadInfo>
                <img src={imageSrc} />
                <div>
                  <div>{configLogo.name}</div>
                  <Buttons style={{ position: 'absolute', right: '0px' }}>
                    <ItemBtn onClick={handleLogoDelete}>
                      <Icon type="delete" />
                    </ItemBtn>
                  </Buttons>
                </div>
              </UploadInfo>
            )}
          </Section>
        )}
        {blockData.lidLogoPosition !== 'Full' && (
          <Section>
            <div>Please enter any text to be displayed on the lid.</div>
            <div>
              <Textarea value={lidLabel} onChange={handleLabelChange} />
            </div>
            <Characters>
              Characters remaining:
              {blockData.lidLabelTextLimit - lidLabel.length}
            </Characters>
          </Section>
        )}
        {blockData.lidLogoPosition !== 'None' && (
          <Section>
            <div
              dangerouslySetInnerHTML={{
                __html: belowTextboxHtml.label,
              }}
            />
          </Section>
        )}
      </SectionBody>
      <SectionFooter handleBack={handleBack} handleContinue={handleContinue} />
    </SectionWrapper>
  );
};

export default container(CustomizeSection);
