import React from 'react';
import { connect } from 'react-redux';
import { actions, selectors } from '../modules';
import { SET_LOGO } from '../modules/utils/actionType';

export default (Component) => {
  const mapDispatchToProps = {
    handleBack: actions.ui.goToPreviousSection,
    handleContinue: actions.ui.goToNextSection,
    setBlock: actions.config.setBlock,
    setSection: actions.ui.goToSection,
    getUIElementByName: selectors.metadata.getUIElementByName,
    setLogo: (logoFile) => ({ type: SET_LOGO, payload: logoFile }),
    getLogoEndpoint: selectors.config.getLogoEndpoint,
    setBlockName: actions.config.setBlockName,
  };
  const mapStateToProps = (state) => ({
    lidLabel: state.config.block.lidLabel || '',
    configBlock: state.config.block,
    configLogo: state.config.logo,
    flow: state.ui.flow,
    blockName: state.config.blockName,
  });
  const WrappedComponent = (props) => <Component {...props} />;

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WrappedComponent);
};
