import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';

import { actions } from '../../modules';
import { UI_FLOW } from '../../constants';

// Sections
import ProcedureSection from '../ProcedureSection';
import InstrumentSection from '../InstrumentSection';
import BlockSection from '../BlockSection';
import CustomizeSection from '../CustomizeSection';
import SummarySection from '../SummarySection';

const flows = {
  [UI_FLOW.procedure]: ['Procedure', 'Block', 'Customize', 'Summary'],
  [UI_FLOW.blank]: ['Block', 'Instruments', 'Customize', 'Summary'],
};

const sections = {
  Procedure: ProcedureSection,
  Instruments: InstrumentSection,
  Block: BlockSection,
  Customize: CustomizeSection,
  Summary: SummarySection,
};

export const Controller = ({
  flow,
  loaded,
  setSection,
  section,
  appHeight,
}) => {
  const handleTabChange = (section) => setSection(section);
  if (!loaded) return <div />;
  const tabs = flows[flow];
  return (
    <Tabs
      defaultActiveKey={tabs[0].toLowerCase()}
      size="small"
      activeKey={section}
      onChange={handleTabChange}
      tabBarGutter={5}
    >
      {tabs.map((tabName) => {
        const Component = sections[tabName];
        return (
          <Tabs.TabPane tab={tabName} key={tabName.toLowerCase()}>
            {<Component height={appHeight} />}
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  );
};

const mapStateToProps = ({ ui, app }) => ({
  flow: ui.flow,
  section: ui.section,
  loaded: app.loaded,
  appHeight: app.appHeight,
});

const mapDispatchToProps = {
  setSection: actions.ui.goToSection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
