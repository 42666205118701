import React from 'react';
import {
  Wrapper,
  Primary,
  Secondary,
  ChildWrapper,
} from './sectionHeading.styles';
import { Header } from '../';
export default ({ title, children, SecondaryChildren, SecondaryProps }) => (
  <Wrapper SecondaryChildren={!!SecondaryChildren}>
    <Primary withChild={!!children}>
      <Header>{title}</Header>
      {children && <ChildWrapper>{children}</ChildWrapper>}
    </Primary>
    {SecondaryChildren && (
      <Secondary>{SecondaryChildren(SecondaryProps)}</Secondary>
    )}
  </Wrapper>
);
