import React from 'react';
import { Icon } from 'antd';

import { AddItemButton } from './addItemButton.style';

export default ({ onClick, label }) => (
  <AddItemButton onClick={onClick}>
    <Icon type="plus-circle" />
    {label}
  </AddItemButton>
);
