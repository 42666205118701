import React from 'react';
import { connect } from 'react-redux';
import { actions, selectors } from '../modules';

export default (Component) => {
  const mapDispatchToProps = {
    //  selectors
    getInstrumentFilter: selectors.ui.getInstrumentFilter,
    getInstruments: selectors.metadata.getInstruments,
    getAvailableInstruments: selectors.metadata.getInstrumentsFromConfigState,
    validateInstruments: selectors.config.filterInstrumentsFromConfigState,
    getUIElementByName: selectors.metadata.getUIElementByName,
    getInstrumentAttributeValues:
      selectors.metadata.getInstrumentAttributeValues,
    //  actions
    addInstruments: actions.config.addInstruments,
    setAnnotation: actions.config.setAnnotation,
    hideAnnotation: actions.config.hideAnnotation,
  };

  const mapStateToProps = ({ config }) => ({
    configInstruments: config.instruments,
    block: config.block,
  });

  const WrappedComponent = (props) => <Component {...props} />;

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WrappedComponent);
};
