import {
  SET_INIT_STATE,
  SET_PRICE,
  SET_SUBMIT_STATUS,
  SET_SAVED_ID,
  SET_UUID,
} from './utils/actionType';

export const initialState = {
  presetCode: null,
  viewCode: null,
  price: 0,
  submitStatus: {},
  savedId: null,
  uuid: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_INIT_STATE:
      return { ...state, ...(payload.order || {}) };
    case SET_PRICE:
      return { ...state, price: payload };
    case SET_SUBMIT_STATUS:
      return { ...state, submitStatus: payload };
    case SET_SAVED_ID:
      return { ...state, savedId: payload, uuid: payload };
    case SET_UUID:
      return { ...state, uuid: payload };
    default:
      return state;
  }
};
