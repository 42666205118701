import styled from 'styled-components';
import { Subtitle } from '../shared/shared.styles';

/* ------------------------------------------------------------------ 
  Index Styles
------------------------------------------------------------------ */

export const SummarySubtitle = styled(Subtitle)`
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: left;
`;

export const StepTitle = styled.div``;
