import React, { useState, useEffect } from 'react';
import container from '../../containers/filterContainer';
import { convertFiltersToQueryObj } from '../../modules/utils/metadata';
import { Icon, Button } from 'antd';
import { CenterVert, ActionButton, FilterButton } from '../shared';
import {
  Wrapper,
  Header,
  Content,
  Section,
  SectionPrimary as Primary,
  SectionSecondary as Secondary,
  Summary,
  // Badge,
} from './filter.styles';

const Filter = ({
  //  parent
  show,
  items,
  total,
  filters,
  handleCloseFilter,
  setFilters,
  clearFilters,
  // container
  getInstruments,
  getAvailableInstruments,
  filterBlockLabels,
  getBlockLabels,
  disableApplyFilter,
  filterTitles,
  itemList,
}) => {
  const [isExpanded, setIsExpanded] = useState(null);
  const [availableItems, setAvailableItems] = useState(total);

  useEffect(
    () => {
      handleUpdateAvailableItems(filters);
    },
    [total]
  );
  const handleClickExpand = ({ currentTarget: e }) => {
    const section = e.name;
    setIsExpanded(isExpanded === section ? null : section);
  };

  const handleClickFilter = (filter, option) => {
    //  Copy over that entire section to a new variable
    const sectionState = { ...filters[filter] };

    //  Update the filter within that copied section
    sectionState[option] = !filters[filter][option];

    //  And then update that section of the state
    const updated = { ...filters, [filter]: sectionState };
    setFilters(updated);

    handleUpdateAvailableItems(updated);
  };

  const handleClearFilters = () => {
    setAvailableItems(total);
    clearFilters();
  };

  const handleUpdateAvailableItems = (filters) => {
    const query = convertFiltersToQueryObj(filters);
    let itemsCount;
    if (items === 'Instruments')
      itemsCount = getInstruments(query, itemList || getAvailableInstruments());
    else if (items === 'Blocks')
      itemsCount = filterBlockLabels(query, itemList || getBlockLabels());
    setAvailableItems(itemsCount.length);
  };

  return (
    <Wrapper show={show}>
      <Header>
        <CenterVert>
          <Icon onClick={handleCloseFilter} type="close-circle" />
        </CenterVert>
        <CenterVert>Filter</CenterVert>
        <CenterVert>
          <Button onClick={handleClearFilters} size="small">
            Clear All
          </Button>
        </CenterVert>
      </Header>
      <Summary>
        <CenterVert>{items} available:</CenterVert>
        <CenterVert>
          {availableItems} of {total}
        </CenterVert>
      </Summary>
      <Content>
        {filters &&
          Object.entries(filters).map(([filter, filterOpts], i) => {
            const expand = isExpanded === filter;
            return (
              <Section key={i}>
                <Primary expand={expand}>
                  <div>
                    <div>
                      {filterTitles
                        ? filterTitles[i]
                        : filter.replace(/([A-Z])/g, ' $1')}
                    </div>
                  </div>
                  <div>
                    <div>
                      <button onClick={handleClickExpand} name={filter}>
                        <Icon type="down" />
                      </button>
                    </div>
                  </div>
                </Primary>
                {expand && (
                  <Secondary>
                    {Object.entries(filterOpts).map(([option, value]) => (
                      <FilterButton
                        key={option}
                        option={option}
                        selected={value}
                        filter={filter}
                        onClick={handleClickFilter}
                      />
                    ))}
                  </Secondary>
                )}
              </Section>
            );
          })}
      </Content>
      <ActionButton
        continueLabel={'Apply Filters'}
        handleContinue={handleCloseFilter}
        disabled={disableApplyFilter}
      />
    </Wrapper>
  );
};

export default container(Filter);
