import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Icon, Button, Popover, Input, Tooltip } from 'antd';
import {
  ShareAltOutlined,
  CopyOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import { SummarySubtitle as Subtitle, StepTitle } from './summary.styles';
import {
  SectionWrapper,
  SectionHeading,
  SectionBody,
  SectionFooter,
  AddItemButton,
} from '../shared';
import SummaryItem from '../Items/SummaryItem';
import container from '../../containers/summarySectionContainer';
import { POST_MESSAGE_TYPE, UI_FLOW, UI_SECTIONS } from '../../constants';

const SummaryHeading = ({ handleClick, url, disabled }) => {
  const onShareLinkCopy = () => {
    const inputEl = document.getElementById('share-link-container');
    inputEl.focus();
    inputEl.select();
    document.execCommand('copy');
  };
  const PopoverContent = (
    <Input
      id="share-link-container"
      value={url}
      placeholder="Generating share link"
      suffix={
        url ? (
          <Tooltip title="Copy share link">
            <CopyOutlined
              style={{ cursor: 'pointer' }}
              onClick={onShareLinkCopy}
            />
          </Tooltip>
        ) : (
          <LoadingOutlined spin />
        )
      }
    />
  );
  return (
    <SectionHeading title="Kit Summary">
      <Popover trigger="click" placement="bottomRight" content={PopoverContent}>
        <Button onClick={handleClick} size="small" disabled={disabled}>
          <ShareAltOutlined />
          Share Configurator
        </Button>
      </Popover>
    </SectionHeading>
  );
};

const SummarySection = ({
  height,
  flow,
  configBlock,
  submitStatus,
  getProcedure,
  getSteps,
  getInstruments,
  handleClickBack,
  postMessageToParent,
  setSection,
  deleteInstruments,
  setSubmitStatus,
  saveConfigurator,
  fetchConfigurator,
  getConfiguratorResumeLink,
  getShareLink,
  uuid,
  savedId,
}) => {
  const procedure = getProcedure && getProcedure();
  const steps = getSteps && getSteps();
  const block = configBlock && configBlock.blockData;
  const instruments = getInstruments();

  const [submitting, setSubmitting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [shareUrl, setShareUrl] = useState();

  useEffect(
    () => {
      setShareUrl();
    },
    [uuid]
  );

  const closeErrorModal = () => {
    setSubmitting(false);
    setSaving(false);
    setSubmitStatus();
  };

  const saveToAws = async () => {
    const savedConfig = await (uuid === savedId
      ? fetchConfigurator(uuid)
      : saveConfigurator());
    const sharableLink = getConfiguratorResumeLink();
    return { ...savedConfig, ...sharableLink, uuid, initialId: savedId };
  };

  const addToCartHandler = async () => {
    setSubmitting(true);
    try {
      const savedConfig = await saveToAws();
      postMessageToParent(POST_MESSAGE_TYPE.addToCart, {
        ...savedConfig,
        checkOut: true,
        uuid,
        initialId: savedId,
      });
    } catch (e) {
      setSubmitStatus({ success: false, errorMsg: e.message });
    }
  };

  const saveConfiguratorHandler = async () => {
    setSaving(true);
    try {
      const savedConfig = await saveToAws();
      postMessageToParent(POST_MESSAGE_TYPE.addToCart, {
        ...savedConfig,
        uuid,
        initialId: savedId,
      });
    } catch (e) {
      setSubmitStatus({ success: false, errorMsg: e.message });
    }
  };

  const shareCongifuratorHandler = async () => {
    try {
      await saveToAws();
      setShareUrl(getShareLink());
    } catch (e) {
      setSubmitStatus({ success: false, errorMsg: e.message });
    }
  };

  const handleAddBlock = () => {
    setSection(UI_SECTIONS[UI_FLOW[flow]][0]);
  };
  const handleAddInstruments = () => {
    setSection(flow === UI_FLOW.procedure ? 'procedure' : 'instruments');
  };
  const disabled = saving || submitting || !uuid;
  return (
    <Fragment>
      <SectionWrapper height={height}>
        <SummaryHeading
          handleClick={shareCongifuratorHandler}
          url={shareUrl}
          disabled={disabled}
        />

        <SectionBody withWrapper>
          <Subtitle>Block</Subtitle>
          {block && block.productNumber ? (
            <SummaryItem key={block.name} {...block} />
          ) : (
            <AddItemButton onClick={handleAddBlock} label="Add a Block" />
          )}
          <Subtitle>
            {procedure ? `Instruments for ${procedure.label}` : 'Instruments'}
          </Subtitle>
          {instruments ? (
            !Array.isArray(instruments) &&
            Object.entries(instruments).map(([step, instruments], i) => (
              <div key={i}>
                {flow === UI_FLOW.procedure && (
                  <StepTitle>Step: {steps[step]}</StepTitle>
                )}
                {instruments.map((instrument, j) => (
                  <SummaryItem
                    key={j}
                    {...instrument}
                    handleDelete={deleteInstruments}
                  />
                ))}
              </div>
            ))
          ) : (
            <AddItemButton
              onClick={handleAddInstruments}
              label="Add some Instruments"
            />
          )}
        </SectionBody>
        <SectionFooter
          handleBack={handleClickBack}
          handleAction={saveConfiguratorHandler}
          actionLabel={saving ? 'Saving' : 'Save Configurator'}
          actionDisabled={disabled}
          handleContinue={addToCartHandler}
          disabled={disabled}
          continueLabel={submitting ? 'Processing' : 'Add to Cart'}
        />
      </SectionWrapper>
      <Modal
        title="Error"
        visible={submitStatus.success === false}
        onOk={closeErrorModal}
        onCancel={closeErrorModal}
      >
        <p>{saving ? `Error saving configurator` : `Error adding to cart`}</p>
        <p>{submitStatus.errorMsg}</p>
      </Modal>
    </Fragment>
  );
};

export default container(SummarySection);
