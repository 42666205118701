import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../modules';
import { BASE_ASSET_ID, AUTH_TOKEN } from '../../constants';
import { PlayerContainer } from './ViewPlayer.styled';
import { fetchMetadata } from '../../modules/utils/app';

class ViewPlayer extends PureComponent {
  constructor(props) {
    super(props);
    this.playerRef = React.createRef();
    this.playerContainerRef = React.createRef();
  }
  async componentDidMount() {
    const { setInitState } = this.props;
    this.playerRef.current.style.height = this.playerContainerRef.current.offsetHeight;
    const metadata = fetchMetadata();
    const threekitApi = window.threekitPlayer({
      authToken: AUTH_TOKEN,
      assetId: BASE_ASSET_ID,
      el: this.playerRef.current,
    });
    window.addEventListener('message', this.messageHandler);
    await setInitState({
      app: { loaded: true },
      threekit: { threekitApi: await threekitApi },
      metadata: await metadata,
    });
  }
  componentWillUnmount() {
    window.removeEventListener('message', this.messageHandler);
  }

  messageHandler = (ev) => {
    if (!window || window.origin === ev.origin) return;
    this.props.parentMessageHandler(ev);
  };

  render() {
    return (
      <PlayerContainer ref={this.playerContainerRef}>
        <div ref={this.playerRef} />
      </PlayerContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  viewCode: state.order.viewCode,
});
const mapDispatchToProps = {
  setInitState: actions.setInitState,
  parentMessageHandler: actions.order.parentMessageHandler,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewPlayer);
