import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../modules';

export default Component => {
  const mapDispatchToProps = {
    //  actions
    setInstruments: actions.config.setInstruments,
    deleteInstrument: actions.config.deleteInstruments,
    getEtchingLabelMssg: actions.config.getEtchingLabelMssg,
  };

  const WrappedComponent = props => <Component {...props} />;

  return connect(null, mapDispatchToProps)(WrappedComponent);
};
