import React from 'react';
import { connect } from 'react-redux';
import ActionButton from '../ActionButton';
import { Wrapper, PriceWrapper } from './sectionFooter.styles';
import { COUNTRY_DATA } from '../../../constants';

const SectionFooter = ({
  price,
  country,
  handleContinue,
  continueLabel = 'Continue',
  continueDisabled,
  handleAction,
  actionLabel = 'Action',
  actionDisabled,
  handleBack,
  backLabel = 'Back',
}) => {
  const priceAsNum = Number(price);
  const formatPrice = Number.isNaN(priceAsNum)
    ? 'LOGIN TO SEE PRICE'
    : Intl.NumberFormat(COUNTRY_DATA[country].currencyFormat, {
        style: 'currency',
        currency: COUNTRY_DATA[country].currency,
      }).format(priceAsNum);
  return (
    <Wrapper>
      <PriceWrapper>
        Total: <span>{formatPrice}</span>
      </PriceWrapper>
      <ActionButton
        disabled={continueDisabled}
        handleContinue={handleContinue}
        continueLabel={continueLabel}
        handleAction={handleAction}
        actionLabel={actionLabel}
        actionDisabled={actionDisabled}
        handleBack={handleBack}
        backLabel={backLabel}
      />
    </Wrapper>
  );
};

const mapStateToProps = ({ order, app }) => ({
  price: order.price,
  country: app.country,
});

export default connect(mapStateToProps)(SectionFooter);
