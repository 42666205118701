import styled from 'styled-components';
import { TRANSITION_DURATION } from '../shared/shared.styles';

/* ------------------------------------------------------------------ 
  Constants
------------------------------------------------------------------ */

const GRID_GAP = '12px';
const HEIGHT = {
  large: '70px',
  small: '50px',
};
const getImageSize = (expand) => (expand ? '120px' : '60px');

/* ------------------------------------------------------------------ 
  Item Styles
------------------------------------------------------------------ */

export const Wrapper = styled.div`
  background: white;
  border-radius: 4px;
  border: 1px solid gray;
  overflow: hidden;
  margin-bottom: 18px;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled || props.inHold ? '0.4' : '1')};

  z-index: 2;

  max-height: ${(props) => (props.expand ? 'unset' : HEIGHT[props.size])};
  min-height: ${(props) =>
    props.size === 'small' ? HEIGHT[props.size] : getImageSize(props.expand)}

  border: ${(props) =>
    props.selected ? '1px solid #00359c' : '1px solid white'};
  box-shadow: ${(props) =>
    props.selected ? '0px 2px 7px rgba(0, 0, 0, 0.19)' : 'none'};
  transform: ${(props) =>
    props.selected ? 'translateY(-2px)' : 'translateY(0px)'};

  transition: all ${TRANSITION_DURATION};
`;

export const StepWrapper = styled(Wrapper)`
  height: auto;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.19);
  background: #f7f7f7;
  border-radius: 3px;
`;

export const Primary = styled.div`
  display: grid;
  grid-template-columns: max-content auto max-content;
  grid-gap: ${GRID_GAP};
  padding: 0 10px;

  width: 100%;
  height: ${(props) => HEIGHT[props.size]};

  transition: all ${TRANSITION_DURATION};
  & > div {
    height: ${(props) => HEIGHT[props.size]};
  }
`;

export const StepPrimary = styled(Primary)`
  grid-gap: 8px;
`;

export const StepTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StepCounter = styled.div`
  background: #e9e9e9;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  font-weight: 600;
  border-radius: 11px;
  padding: 3px 6px;
  width: max-content;
  margin-left: 4px;
`;

export const StepItemButton = styled.div`
  cursor: pointer;

  svg {
    font-size: 14px;
  }

  &:hover svg {
    color: #00359c;
  }
`;

export const ExpandButton = styled(StepItemButton)`
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};

  svg {
    transform: ${(props) => (props.expand ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: all ${TRANSITION_DURATION};
  }
`;

export const Secondary = styled.div`
  padding-left: calc(130px + ${GRID_GAP});
  padding-right: 10px;
  overflow-y: auto;
  transition: all ${TRANSITION_DURATION};
`;

export const StepSecondary = styled(Secondary)`
  padding-left: 15px;
`;

export const SecondaryText = styled.div`
  margin-bottom: 10px;
`;

export const Image = styled.div`
  height: ${(props) => getImageSize(props.expand)};
  width: ${(props) => getImageSize(props.expand)};
  transition: all ${TRANSITION_DURATION};

  img {
    height: 100%;
    width: 100%;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ItemBtn = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 10px;
  padding: 0;

  svg {
    transform: ${(props) => (props.expand ? 'rotate(180deg)' : 'rotate(0deg)')};
    font-size: 20px;
    transition: all ${TRANSITION_DURATION};
  }

  &:hover svg {
    color: #00359c;
  }
`;

export const TagWrapper = styled.div`
  height: 0px;
  overflow: visible;
  z-index: 1;

  & > div {
    position: relative;
    top: 0;
    left: 100%;
    transform: translateX(-105%) translateY(-35%);

    z-index: 1;

    background: white;
    width: max-content;
    padding: 2px 8px;
    background: #00359c;
    border-radius: 3px;
    font-size: 12px;
    color: white;
    letter-spacing: 1px;

    opacity: ${(props) => (props.disabled ? '0.4' : '1')};
    transition: all ${TRANSITION_DURATION};
  }
`;

/* ------------------------------------------------------------------ 
  Procedure Item Styles
------------------------------------------------------------------ */

export const ProcedureSecondary = styled(Secondary)`
  padding-left: 21px;
`;
