import { SET_INIT_STATE } from './utils/actionType';

export const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_INIT_STATE:
      return { ...state, ...(payload.metadata || {}) };
    default:
      return state;
  }
};
