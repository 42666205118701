import React, { useState, Fragment } from 'react';
import { Checkbox } from 'antd';
import { SectionFooter, SectionHeading, SectionBody } from '../shared';
import container from '../../containers/instrumentSelectedContainer';
import Item from '../Items/SelectedItem';
import { HOLDING } from '../../constants';

//  COMPONENTS FOR: Instrument Selected

const InstrumentSelected = ({
  handleContinue,
  handleBack,
  configInstruments,
  configBlock,
  silhouetteVisible,
  setSilhouetteVisibility,
}) => {
  const [expandedItems, setExpandedItems] = useState(new Set([]));

  const handleExpand = (idx) => {
    const updatedExpanded = new Set(expandedItems);
    expandedItems.has(idx)
      ? updatedExpanded.delete(idx)
      : updatedExpanded.add(idx);
    setExpandedItems(updatedExpanded);
  };
  const disabled = !configBlock.blockData.silhouette;
  return (
    <Fragment>
      <SectionHeading title="Selected Instruments">
        <Checkbox
          disabled={disabled}
          checked={silhouetteVisible}
          onChange={(e) => setSilhouetteVisibility(e.target.checked)}
        >
          Turn On Silhouette
        </Checkbox>
      </SectionHeading>

      <SectionBody withWrapper>
        {Object.values(configInstruments).map((instrument, i) => (
          <Item
            handleExpand={handleExpand}
            expandedList={expandedItems}
            key={i}
            idx={i}
            inHold={instrument.holeShank === HOLDING.shankName}
            {...instrument}
          />
        ))}
      </SectionBody>
      <SectionFooter
        handleBack={handleBack}
        handleContinue={handleContinue}
        backLabel="Add Instruments"
      />
    </Fragment>
  );
};

export default container(InstrumentSelected);
