import React, { useState } from 'react';
import {
  Wrapper,
  Primary,
  ProcedureSecondary as Secondary,
  SecondaryText,
  Buttons,
  ItemBtn,
} from './items.styles';
import { CenterVert } from '../shared';
import { Icon } from 'antd';

export const ProcedureItem = ({
  id,
  title,
  description,
  onClick,
  selected,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClickProcedure = () => onClick(id);

  const handleClickExpand = (e) => {
    setIsExpanded(!isExpanded);
    e.stopPropagation();
  };

  return (
    <Wrapper
      onClick={handleClickProcedure}
      name={title}
      selected={selected}
      expand={isExpanded}
      size="small"
    >
      <Primary size="small">
        {/* keep the first empty div, the primary set child div styles */}
        <div />
        <CenterVert>{title}</CenterVert>
        <CenterVert>
          <Buttons>
            <ItemBtn onClick={handleClickExpand} expand={isExpanded}>
              <Icon type="down" />
            </ItemBtn>
          </Buttons>
        </CenterVert>
      </Primary>
      {isExpanded && (
        <Secondary>
          <SecondaryText>{description}</SecondaryText>
        </Secondary>
      )}
    </Wrapper>
  );
};

export default ProcedureItem;
