import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import ConfigPage from './configPage';
import OrderPage from './orderPage';
import ViewPage from './viewPage';

class Page extends PureComponent {
  render() {
    const { orderId, viewCode } = this.props;
    if (orderId) return <OrderPage />;
    if (viewCode) return <ViewPage />;
    return <ConfigPage />;
  }
}

const mapStateToProps = ({ order }) => ({
  orderId: order.orderId,
  viewCode: order.viewCode,
});
export default connect(mapStateToProps)(Page);
