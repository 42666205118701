import styled from 'styled-components';

export const AddItemButton = styled.div`
  border: 2px dashed gray;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  padding: 12px 0;
  cursor: pointer;

  &:hover {
    border: 2px dashed #00359c;
    color: #00359c;
  }

  & > i {
    font-size: 18px;
    margin-right: 5px;
  }
`;
