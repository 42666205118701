import React from 'react';
import { connect } from 'react-redux';
import { actions, selectors } from '../modules';

export default Component => {
  const mapStateToProps = state => ({
    configBlock: state.config.block.productNumber,
  });
  const mapDispatchToProps = {
    //  Actions
    setBlock: actions.config.setBlock,

    //  Selectors
    getBlocksByProductLabel: selectors.metadata.getBlocksByProductLabel,
  };

  const WrappedComponent = props => <Component {...props} />;

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent);
};
