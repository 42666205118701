export const filterFromQuery = (queryObject, datas) => {
  if (!datas) return [];
  const query = parseQueryObject(queryObject);
  return datas.filter((data) => filterDataFromQuery(query, data));
};

function parseQueryObject(queryObject) {
  return Object.entries(queryObject).reduce((parsedQuery, [key, values]) => {
    let queryType, queryValue;
    if (typeof values === 'string') {
      queryType = 'map';
      queryValue = { [values]: true };
    } else if (Array.isArray(values)) {
      queryType = 'map';
      queryValue = values.reduce(
        (map, value) => Object.assign(map, { [value]: true }),
        {}
      );
    } else {
      queryType = values.type;
      queryValue = values.value;
    }
    return Object.assign(parsedQuery, {
      [key]: { type: queryType, value: queryValue },
    });
  }, {});
}

function filterDataFromQuery(query, dataObj) {
  let match = true;
  const queryAttributes = Object.keys(query);
  for (let idx = 0; idx < queryAttributes.length && match; ++idx) {
    const attribute = queryAttributes[idx];
    if (dataObj[attribute] === undefined) return false;

    const { type: queryType, value: queryValue } = query[attribute];
    switch (queryType) {
      case 'map':
        match = filterDataFromMapQuery(queryValue, dataObj[attribute]);
        break;
      case 'range':
        match = filterDataFromRangeQuery(queryValue, dataObj[attribute]);
        break;
      default:
        console.error(`Unknow query type ${queryType}`);
    }
  }
  return match;
}

function filterDataFromMapQuery(queryMap, attributeData) {
  const dataValues = Array.isArray(attributeData)
    ? attributeData
    : [attributeData];

  let match = false;
  for (let valueIdx = 0; valueIdx < dataValues.length && !match; ++valueIdx)
    match = !!queryMap[dataValues[valueIdx]];
  return match;
}
function filterDataFromRangeQuery(queryRange, attributeData) {
  return attributeData <= queryRange.max && attributeData >= queryRange.min;
}

export const convertFiltersToQueryObj = (filters) =>
  Object.entries(filters).reduce((query, [property, filterValue]) => {
    let queryValue = [];
    Object.entries(filterValue).forEach(
      ([value, flag]) => flag && queryValue.push(value)
    );
    if (queryValue.length) query[property] = queryValue;
    return query;
  }, {});

export const buildRecommendedProductList = (
  productList = [],
  recommendedList = []
) => {
  const orderList = recommendedList.concat(
    productList.filter(
      (product) => !recommendedList.find(({ name }) => product.name === name)
    )
  );
  return orderList.map((product, idx) => ({
    ...product,
    recommended: idx < recommendedList.length,
  }));
};
