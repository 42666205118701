import {
  SET_BLOCK,
  SET_PRICE,
  SET_SUBMIT_STATUS,
  SET_PROCEDURE,
  SET_SAVED_ID,
  SET_LOGO,
  SET_SILHOUETTE_VISIBLE,
  SET_BLOCK_NAME,
} from '../utils/actionType';
import { POST_MESSAGE_TYPE, RECEIVE_MESSAGE_TYPE } from '../../constants';
import {
  createBlockInstance,
  setBlockInstanceConfiguration,
  createInstrumentInstance,
  getImageAspectRatio,
  frameScene,
} from '../utils/config';
import configActions from './config';
import appActions from './app';
import metadataSelectors from '../selectors/metadata';
import orderSelectors from '../selectors/order';

const {
  getBlockByProductNumber,
  getInstrumentByProductNumber,
} = metadataSelectors;
const {
  getPresetEndpoint,
  getProductList,
  getConfiguration,
  getSnapshot,
  getArtfileLink,
} = orderSelectors;
const { setBlock, addInstruments } = configActions;
const { postMessageToParent } = appActions;

const loadProductFromViewCode = () => async (dispatch, getState) => {
  const state = getState();
  const { viewCode } = state.order;
  const { threekitApi } = state.threekit;

  const blockProductData = dispatch(getBlockByProductNumber(viewCode));
  let optionalFrameId;
  if (blockProductData) {
    const holesData = await createBlockInstance(threekitApi, blockProductData);
    setBlockInstanceConfiguration(threekitApi, {
      'Block Color': blockProductData.colorAssetId,
    });
    dispatch({
      type: SET_BLOCK,
      payload: { ...holesData, productNumber: viewCode },
    });
  }

  const instrumentProductData = dispatch(
    getInstrumentByProductNumber(viewCode)
  );
  if (instrumentProductData) {
    const instrument = await createInstrumentInstance(
      threekitApi,
      instrumentProductData
    );
    optionalFrameId = [instrument.modelId];
  }
  if (blockProductData || instrumentProductData)
    return setTimeout(() => frameScene(threekitApi, optionalFrameId), 1000);
  dispatch(loadProductFromPresetCode(viewCode));
};

const loadProductFromPresetCode = (optionalPresetCode) => async (
  dispatch,
  getState
) => {
  const { order } = getState();
  const presetCode = optionalPresetCode || order.presetCode;

  try {
    const preset = await dispatch(fetchConfigurator(presetCode));
    if (preset.configuration.block.lidLogo) {
      const logoName = preset.configuration.logoOriginalName || 'resume.svg';
      const fileAsBolb = await fetch(dispatch(getArtfileLink(presetCode)), {
        headers: { 'Content-type': 'image/svg+xml' },
      }).then((res) => res.blob());

      const file = new File([fileAsBolb], logoName, { type: 'image/svg+xml' });
      dispatch({
        type: SET_LOGO,
        payload: { originFileObj: file, name: logoName },
      });
    }
    dispatch(resumeConfigurator(preset.configuration));
    dispatch({ type: SET_SAVED_ID, payload: presetCode });
  } catch (e) {
    console.error('Load configuration error!', e);
  }
};

const resumeConfigurator = (configuration) => async (dispatch, getState) => {
  const { config } = getState();
  const { logo } = config;
  const { selectedProcedure, block, instruments, blockName } = configuration;
  selectedProcedure &&
    dispatch({
      type: SET_PROCEDURE,
      payload: selectedProcedure,
    });

  const blockConfiguration = logo && {
    'Lid Logo Aspect Ratio': await getImageAspectRatio(logo.originFileObj),
  };
  // compatible to the old data before include lid log and label into block configuration
  const blockState =
    typeof block === 'string' ? { productNumber: block } : block;
  await dispatch(
    setBlock(blockState, {
      disableUuidUpdate: true,
      configuration: blockConfiguration,
    })
  );
  const instrument = configuration.instruments[0];
  !!(instrument && instrument.silhouetteVisible) &&
    dispatch({ type: SET_SILHOUETTE_VISIBLE, payload: true });
  dispatch({ type: SET_BLOCK_NAME, payload: blockName });
  instruments.length &&
    (await dispatch(
      addInstruments(instruments, { highlight: false, disableUuidUpdate: true })
    ));
};

const saveConfigurator = () => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    const { order, config } = getState();
    const { uuid, savedId } = order;
    if (uuid === savedId)
      reject('Save configurator error! Configurator has already been saved.');
    const body = {
      uuid,
      productList: dispatch(getProductList()),
      configuration: await dispatch(getConfiguration()),
      snapshot: await dispatch(
        getSnapshot({ size: { width: 500, height: 400 } })
      ),
    };
    const { logo } = config;
    const formData = new FormData();
    formData.append('data', JSON.stringify(body));
    if (logo) formData.append('logo', logo.originFileObj);

    const endpoint = dispatch(getPresetEndpoint());
    fetch(endpoint, {
      method: 'POST',
      body: formData,
    })
      .then((res) => {
        if (res.status !== 200)
          throw new Error(`Save configurator error with status ${res.status}!`);
        return res.json();
      })
      .then((json) => {
        dispatch({ type: SET_SAVED_ID, payload: uuid });
        resolve(json);
      })
      .catch((e) => reject(e));
  });

const fetchConfigurator = (presetCode) => (dispatch) =>
  new Promise((resolve, reject) => {
    const endpoint = dispatch(getPresetEndpoint());
    fetch(`${endpoint}/${presetCode}`)
      .then((res) => {
        if (res.status !== 200)
          throw new Error(
            `Fetch configuration with preset code ${presetCode} error with status ${
              res.status
            }!`
          );
        return res.json();
      })
      .then((json) => resolve(json))
      .catch((e) => reject(e));
  });

// use to handle message post from parent site to iframe
export const parentMessageHandler = (ev) => async (dispatch, getState) => {
  const state = getState();
  const { parentOrigin, env } = state.app;
  if (parentOrigin !== '*' && ev.origin !== parentOrigin) return;
  let data;
  try {
    data = JSON.parse(ev.data);
  } catch (e) {
    console.error(
      'Invalid Message data. Message from parent window must be JSON.'
    );
  }
  env !== 'production' &&
    console.log(
      '%cIframe receive message from parent site:',
      'color: blue; font-size: 12px',
      data
    );
  const { messageType, value } = data;
  switch (messageType) {
    case RECEIVE_MESSAGE_TYPE.updatePrice:
      if (value && !Number.isNaN(value.totalPrice))
        dispatch({ type: SET_PRICE, payload: value.totalPrice });
      return;
    case RECEIVE_MESSAGE_TYPE.getConfiguration:
      return dispatch(postMessageToParent(POST_MESSAGE_TYPE.getConfiguration));
    case RECEIVE_MESSAGE_TYPE.addToCart:
      return dispatch({ type: SET_SUBMIT_STATUS, payload: value });
    default:
      return console.error(
        `Handle message from parent site error! Unknow message type ${messageType}!`
      );
  }
};

export default {
  loadProductFromViewCode,
  loadProductFromPresetCode,
  parentMessageHandler,
  fetchConfigurator,
  saveConfigurator,
};
