import React, { useState, useEffect } from 'react';
import { Icon } from 'antd';

import container from '../../containers/blockSectionContainer';
import BlockSelector from './BlockSelector';
import BlockSelected from './BlockSelected';
import {
  SectionWrapper,
  SubsetionWrapper,
  SectionHeading,
  AddItemButton,
} from '../shared';
import { UI_FLOW } from '../../constants';

const BlockSection = ({
  flow,
  procedure,
  configBlock,
  configBlockData,
  configInstruments,
  handleBack,
  handleContinue,
  height,
}) => {
  const [showSelected, setShowSelected] = useState(!!configBlock);
  const [preselectedBlockLabel, setpreselectedBlockLabel] = useState(
    configBlockData && configBlockData.productLabel
  );
  const [validation, setValidation] = useState(false);

  useEffect(
    () => {
      if (!validation) setValidation(true);
    },
    [configInstruments]
  );

  useEffect(
    () => {
      if (!configBlock) return;
      setpreselectedBlockLabel(configBlockData.productLabel);
    },
    [configBlock]
  );

  if (flow === UI_FLOW.procedure && !procedure)
    return (
      <SectionWrapper>
        <SectionHeading title="Procedure is requried to config block!" />
        <AddItemButton label="Select procedure" onClick={handleBack} />
      </SectionWrapper>
    );

  return (
    <SectionWrapper height={height}>
      <SubsetionWrapper show index={0}>
        <BlockSelector
          blockLabel={preselectedBlockLabel}
          setBlockLabel={setpreselectedBlockLabel}
          validation={validation}
          setValidation={setValidation}
          handleBack={flow === UI_FLOW.procedure && handleBack}
          handleContinue={() => setShowSelected(true)}
        />
      </SubsetionWrapper>
      <SubsetionWrapper show={showSelected} index={1}>
        <BlockSelected
          blockLabel={preselectedBlockLabel}
          handleBack={() => setShowSelected(false)}
          handleContinue={handleContinue}
        />
      </SubsetionWrapper>
    </SectionWrapper>
  );
};

export default container(BlockSection);
