import React from 'react';
import { connect } from 'react-redux';
import { selectors } from '../modules';

export default Component => {
  const mapStateToProps = state => ({
    configBlock: state.config.block.productNumber,
  });
  const mapDispatchToProps = {
    //  selectors
    getInstruments: selectors.metadata.getInstruments,
    getAvailableInstruments: selectors.metadata.getInstrumentsFromConfigState,
    filterBlockLabels: selectors.metadata.getBlockLabels,
    getBlockLabels: selectors.metadata.getBlockLabelsFromConfigState,
  };

  const WrappedComponent = props => <Component {...props} />;

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent);
};
