import React, { useState, useEffect } from 'react';
import { Icon } from 'antd';

import InstrumentSelector from './InstrumentSelector';
import InstrumentSelected from './InstrumentSelected';
import {
  SectionHeading,
  SectionWrapper,
  AddItemButton,
  SubsetionWrapper,
} from '../shared';
import container from '../../containers/instrumentSectionContainer';

const InstrumentSection = ({
  height,
  configBlock,
  configInstruments,
  handleBack,
  handleContinue,
  setSection,
}) => {
  const [showSelected, setShowSelected] = useState(
    !!Object.keys(configInstruments).length
  );

  useEffect(
    () => {
      if (showSelected && !Object.keys(configInstruments).length)
        setShowSelected(false);
    },
    [configInstruments]
  );
  if (!configBlock.productNumber)
    return (
      <SectionWrapper height={height}>
        <SectionHeading title="Block is required for adding instruments!" />
        <AddItemButton
          onClick={() => setSection('block')}
          label="Add a Block"
        />
      </SectionWrapper>
    );
  return (
    <SectionWrapper height={height}>
      <SubsetionWrapper show index={0}>
        <InstrumentSelector
          handleBack={handleBack}
          handleContinue={() => setShowSelected(true)}
          handleNextSection={handleContinue}
        />
      </SubsetionWrapper>
      <SubsetionWrapper show={showSelected} index={1}>
        <InstrumentSelected
          handleBack={() => setShowSelected(false)}
          handleContinue={handleContinue}
        />
      </SubsetionWrapper>
    </SectionWrapper>
  );
};

export default container(InstrumentSection);
