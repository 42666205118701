import styled from 'styled-components';

export const TAB_HEIGHT = 52;
export const TRANSITION_DURATION = '0.4s';
export const ACTION_BUTTON_HEIGHT = 32;
export const SECTION_FOOTER_HEIGHT = 72;
export const SMALL_SECTION_HEADER_HEIGHT = 54;
export const LARGE_SECTION_HEADER_HEIGHT = 100;
export const SECTION_MARGIN = 16;

export const CenterVert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const SectionWrapper = styled.div`
  background: white;
  position: relative;
  transition: all ${TRANSITION_DURATION};
  height: ${(props) => `${props.height - TAB_HEIGHT}px`};
`;

export const SubsetionWrapper = styled(SectionWrapper)`
  height: 100%;
  transform: ${({ show, index = 0 }) => show && `translateY(-${100 * index}%)`};
`;

export const Header = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
`;

export const Subtitle = styled.div`
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
`;
