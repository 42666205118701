import uuid from 'uuid';
import {
  SET_SELECTED_MODEL,
  SET_BLOCK,
  ADD_INSTRUMENTS,
  DELETE_INSTRUMENTS,
  SET_INSTRUMENTS,
  SET_HOLDING,
  SET_LID_ANIMATION,
  SET_UUID,
  SET_SILHOUETTE_VISIBLE,
  SET_BLOCK_NAME,
} from '../utils/actionType';
import {
  highlightInstrument,
  createBlockInstance,
  setBlockInstanceConfiguration,
  createInstrumentInstance,
  validInstrumentBasedOnLayout,
  getInstrumentTranslation,
  getOccipiedHole,
  getModelXRotation,
  updateInstrument,
  fetchBlockHoleData,
  validEtchingLabelByLimit,
  frameScene,
  initialTopDecalTexture,
  parseEtchingLabel,
  setInsturmentDecalPosition,
  getInstrumentDecalPxSize,
} from '../utils/config';
import { getAssetInstance } from '../utils/threekit';
import {
  BLOCK_ROOT,
  LID_ROOT,
  UI_FLOW,
  POST_MESSAGE_TYPE,
  LID_ANIMATION,
  MM_TO_M,
  HOLDING,
} from '../../constants';
import metadataSelectors from '../selectors/metadata';
import appActions from './app';
import configSelectors from '../selectors/config';
const {
  getBlockLabels,
  getBlockByProductNumber,
  getInstrumentByProductNumber,
} = metadataSelectors;
const { postMessageToParent } = appActions;
const { filterBlockFromConfigState } = configSelectors;

const setHoldingOffset = (zOffset) => (dispatch, getState) => {
  const holding = getState().config.holding;
  const newHolding = { ...holding };
  // this does mutate the original state, however, we still change the reference of the holding state
  Object.values(newHolding).forEach(
    (holdingData) => (holdingData.translation.z = zOffset)
  );
  dispatch({
    type: SET_HOLDING,
    payload: newHolding,
  });
};

const setSelectedModel = (modelId) => (dispatch, getState) => {
  const state = getState();
  const { threekitApi } = state.threekit;
  highlightInstrument(threekitApi, modelId);
  return dispatch({ type: SET_SELECTED_MODEL, payload: modelId });
};

const setBlock =
  (blockState, options = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const { block, silhouetteVisible } = state.config;
    const { threekitApi } = state.threekit;
    const { blockData: prevBlockData } = block;
    const { optionalInstrumentUpdates, disableUuidUpdate, configuration } =
      options;

    if (!prevBlockData && !blockState.productNumber)
      throw new Error('The productNumber is necessary to initial block!');
    const newBlockState = { ...block, ...blockState };
    let updatePrice,
      instrumentUpdates,
      blockConfiguration = configuration || {};
    if (blockState.productNumber) {
      const blockData = dispatch(
        getBlockByProductNumber(blockState.productNumber)
      );
      Object.assign(newBlockState, { blockData });
      updatePrice = true;
      if (
        !prevBlockData ||
        prevBlockData.productLabel !== blockData.productLabel
      ) {
        dispatch(setHoldingOffset(blockData.depth * MM_TO_M));
        const holesData = await createBlockInstance(threekitApi, blockData);
        frameScene(threekitApi);
        Object.assign(newBlockState, holesData);
        instrumentUpdates =
          optionalInstrumentUpdates ||
          (await dispatch(filterBlockFromConfigState(blockData))).instruments;
        if (!instrumentUpdates)
          throw new Error('Can not setBlock because of invalid instruments!');
        if (instrumentUpdates.length) {
          instrumentUpdates.forEach((instrument) => {
            const { decalPlaneId, holeShank, holeIdx } = instrument;
            instrument.etchingLabel = validEtchingLabelByLimit(
              instrument.etchingLabel,
              blockData.etchingLabelTextLimit
            );
            const instrumentConfig = {
              Position: blockData.silhouettePosition,
              'Silhouette Size': getInstrumentDecalPxSize(
                blockData.silhouetteSize
              ),
              'Silhouette Position': blockData.silhouettePosition,
              'Etching Label': instrument.etchingLabel,
              'Silicon Insert': blockData.siliconInsert,
              'Font Size': getInstrumentDecalPxSize(blockData.etchingLabelFont),
            };
            updateInstrument(instrument.configurator, instrumentConfig);
            setInsturmentDecalPosition(
              threekitApi,
              decalPlaneId,
              holesData.holeData[holeShank][holeIdx],
              blockData.instrumentHeightOffset
            );
          });
        } else instrumentUpdates = undefined;
        initialTopDecalTexture(threekitApi, blockData);
        if (silhouetteVisible && !blockData.silhouette)
          dispatch(setSilhouetteVisibility(false));
      } else {
        Object.assign(blockConfiguration, {
          'Block Color': { assetId: blockData.colorAssetId },
        });
      }
    }

    if (blockState.lidLabel !== undefined) {
      Object.assign(blockConfiguration, {
        'Lid Label': parseEtchingLabel(blockState.lidLabel),
      });
    }
    if (blockState.lidLogo !== undefined) {
      Object.assign(blockConfiguration, {
        'Lid Logo': { assetId: blockState.lidLogo },
      });
    }
    setBlockInstanceConfiguration(threekitApi, blockConfiguration);

    dispatch({ type: SET_BLOCK, payload: newBlockState });
    instrumentUpdates && dispatch(setInstruments(instrumentUpdates));
    !disableUuidUpdate && dispatch({ type: SET_UUID, payload: uuid() });
    updatePrice &&
      dispatch(postMessageToParent(POST_MESSAGE_TYPE.productListChange));
  };

const addInstruments =
  (instrumentsData, options = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const { block, instruments, holding, silhouetteVisible } = state.config;
    const { threekitApi } = state.threekit;
    const { selectedStep, flow } = state.ui;
    const { highlight = true, disableUuidUpdate } = options;

    const isInputArray = Array.isArray(instrumentsData);
    const inputDataAsArray = isInputArray ? instrumentsData : [instrumentsData];
    const localOccipiedHole = getOccipiedHole(
      block.holeData,
      instruments,
      holding
    );
    const {
      etchingLabelTextLimit,
      etchingLabelFont,
      silhouettePosition,
      silhouetteSize,
      siliconInsert,
      instrumentHeightOffset,
    } = block.blockData;

    const localInstruments = { ...instruments };
    const newInstruments = await Promise.all(
      inputDataAsArray.map(async (inputData) => {
        const step =
          inputData.step || (flow === UI_FLOW.procedure && selectedStep);
        const instrumentData = dispatch(
          getInstrumentByProductNumber(inputData.productNumber)
        );
        if (inputData.etchingLabel) {
          instrumentData.etchingLabel = inputData.etchingLabel;
        }
        let { holeShank, holeIdx } = inputData;
        if (!holeShank || !holeIdx) {
          const validHole = validInstrumentBasedOnLayout(
            block,
            localInstruments,
            instrumentData
          );
          if (!validHole)
            throw new Error(`Add instrument error: no available hole!`);
          holeShank = validHole.holeShank;
          holeIdx = validHole.holeIdx;
        }

        if (localOccipiedHole[holeShank][holeIdx])
          throw new Error(
            `Add instrument error: target position not available!`
          );
        const holeData = (
          block.holeData[holeShank] ? block.holeData[holeShank] : holding
        )[holeIdx];

        const model = await createInstrumentInstance(
          threekitApi,
          instrumentData,
          {
            holeData,
            silhouette: silhouetteVisible,
            etchingLabelTextLimit,
            etchingLabelFont,
            silhouettePosition,
            silhouetteSize,
            siliconInsert,
            instrumentHeightOffset,
          }
        );
        const newInstrument = Object.assign({}, instrumentData, model, {
          holeShank,
          holeIdx,
          step,
        });

        localInstruments[newInstrument.modelId] = newInstrument;
        localOccipiedHole[holeShank][holeIdx] = newInstrument.modelId;
        return newInstrument;
      })
    );
    highlight &&
      highlightInstrument(
        threekitApi,
        newInstruments[newInstruments.length - 1].modelId
      );
    dispatch({ type: ADD_INSTRUMENTS, payload: newInstruments });
    !disableUuidUpdate && dispatch({ type: SET_UUID, payload: uuid() });
    return dispatch(postMessageToParent(POST_MESSAGE_TYPE.productListChange));
  };

const deleteInstruments = (modelIds) => (dispatch, getState) => {
  const state = getState();
  const { threekitApi } = state.threekit;
  const { instruments } = state.config;
  const deleteModelIds = modelIds
    ? Array.isArray(modelIds)
      ? modelIds
      : [modelIds]
    : Object.keys(instruments);
  deleteModelIds.forEach((modelId) => {
    threekitApi.scene.deleteNode(modelId);
  });
  dispatch({ type: DELETE_INSTRUMENTS, payload: deleteModelIds });
  dispatch({ type: SET_UUID, payload: uuid() });
  return dispatch(postMessageToParent(POST_MESSAGE_TYPE.productListChange));
};

const setInstruments = (newInstrumentsData) => (dispatch, getState) => {
  const state = getState();
  const { threekitApi } = state.threekit;
  const { instruments, block, holding } = state.config;
  const { etchingLabelTextLimit, instrumentHeightOffset } = dispatch(
    getBlockByProductNumber(block.productNumber)
  );

  const data = Array.isArray(newInstrumentsData)
    ? newInstrumentsData
    : [newInstrumentsData];

  data.forEach((instrumentData) => {
    const {
      modelId,
      etchingLabel: newEtchingLabel,
      holeShank: newHoleShank,
      holeIdx: newHoleIdx,
    } = instrumentData;
    if (!modelId) throw new Error(`ModelId is mandatory for setInstruments!`);
    if (!instruments[modelId])
      throw new Error(
        `Can not set instrument with modelId ${modelId}, instrument not exsit!`
      );
    if (newHoleShank && newHoleIdx) {
      const newHoleData = (
        newHoleShank === HOLDING.shankName
          ? holding
          : block.holeData[newHoleShank]
      )[newHoleIdx];
      threekitApi.scene.set(
        { id: modelId, plug: 'Transform', property: 'translation' },
        getInstrumentTranslation(
          newHoleData.translation,
          instrumentHeightOffset
        )
      );
    } else if (newEtchingLabel !== undefined) {
      const { configurator } = instruments[modelId];
      const validEtchingLabel = validEtchingLabelByLimit(
        newEtchingLabel,
        etchingLabelTextLimit
      );
      instrumentData.etchingLabel = validEtchingLabel;
      updateInstrument(configurator, { 'Etching Label': validEtchingLabel });
    }
  });
  dispatch({ type: SET_UUID, payload: uuid() });
  return dispatch({
    type: SET_INSTRUMENTS,
    payload: data,
  });
};

const getEtchingLabelMssg = () => (_, getState) => {
  const state = getState();
  const { etchingLabelTextLimit } = state.config.block.blockData;
  let msg = '';
  if (etchingLabelTextLimit) {
    const numLines = etchingLabelTextLimit.length;
    const charsPerRow = etchingLabelTextLimit[0];
    msg = `Add up to ${numLines} line${
      numLines > 1 ? 's' : ''
    } of ${charsPerRow} character${charsPerRow > 1 ? 's' : ''}\n`;
  }
  return `${msg}Use ';' to start new line`;
};

const animateLid = (optionalDuration) => async (dispatch, getState) => {
  const state = getState();
  const { threekitApi } = state.threekit;
  const { lidAnimation, block } = state.config;

  const duration = optionalDuration || LID_ANIMATION.duration;
  let angle = 0,
    finishStatus = LID_ANIMATION.close;
  if (lidAnimation.status === LID_ANIMATION.close) {
    angle = LID_ANIMATION.angle;
    finishStatus = LID_ANIMATION.open;
  }

  const blockModelId = threekitApi.scene.findNode({ name: BLOCK_ROOT });
  const blockInstance = await getAssetInstance(threekitApi, {
    id: blockModelId,
    plug: 'Null',
    property: 'asset',
  });
  const lidId = threekitApi.scene.findNode({
    from: blockInstance,
    name: LID_ROOT,
  });
  if (!lidId)
    throw new Error(
      `Block ${block.productNumber} missing lid root node for lid animation!`
    );
  const lidRotation = threekitApi.scene.get({
    id: lidId,
    plug: 'Transform',
    property: 'rotation',
  });
  const xRotation = getModelXRotation(threekitApi, lidRotation);
  const angelStep =
    (angle - xRotation) / Math.ceil(duration / LID_ANIMATION.step);
  let degree = xRotation;
  let totalTime = 0;
  dispatch({ type: SET_LID_ANIMATION, payload: { playing: true } });

  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (totalTime >= duration) {
        clearInterval(interval);
        dispatch({
          type: SET_LID_ANIMATION,
          payload: { playing: false, status: finishStatus },
        });
        resolve(true);
      }
      degree += angelStep;
      totalTime += LID_ANIMATION.step;
      threekitApi.scene.set(
        { id: lidId, plug: 'Transform', property: 'rotation' },
        [degree, 0, 0]
      );
    }, LID_ANIMATION.step);
  });
};

const setAnnotation = (holeData) => (_, getState) => {
  const state = getState();
  const { threekitApi } = state.threekit;
  const { annotationId } = state.config;
  const { translation } = holeData;
  threekitApi.scene.set(
    { id: annotationId, plug: 'Transform', property: 'translation' },
    translation
  );
  threekitApi.scene.set(
    { id: annotationId, plug: 'Properties', property: 'visible' },
    true
  );
};

const hideAnnotation = () => (_, getState) => {
  const state = getState();
  const { annotationId } = state.config;
  const { threekitApi } = state.threekit;
  threekitApi.scene.set(
    { id: annotationId, plug: 'Properties', property: 'visible' },
    false
  );
};

const preFetchBlocks =
  (parallel = 5) =>
  (dispatch, getState) => {
    async function parallelFetch(blockLabels) {
      if (!blockLabels.length) return;
      const fetchLabels = blockLabels.splice(0, parallel);
      await Promise.all(
        fetchLabels.map((blockData) =>
          fetchBlockHoleData(threekitApi, blockData)
        )
      );
      parallelFetch(blockLabels);
    }
    const state = getState();
    const { threekitApi } = state.threekit;
    const blockLabels = dispatch(getBlockLabels()).filter(
      (blockLabel) => blockLabel.assetId
    );
    parallelFetch(blockLabels);
  };

const setSilhouetteVisibility = (visible) => (dispatch, getState) => {
  const { config } = getState();
  const { instruments } = config;
  Object.values(instruments).forEach((instrument) =>
    updateInstrument(instrument.configurator, {
      Silhouette: { assetId: visible ? instrument.silhouetteAssetId : null },
    })
  );

  dispatch({
    type: SET_SILHOUETTE_VISIBLE,
    payload: visible,
  });
};

const setBlockName = (name) => (dispatch) => {
  dispatch({ type: SET_BLOCK_NAME, payload: name });
  dispatch({ type: SET_UUID, payload: uuid() });
};

export default {
  setHoldingOffset,
  setSelectedModel,
  setBlock,
  addInstruments,
  deleteInstruments,
  animateLid,
  setInstruments,
  setAnnotation,
  hideAnnotation,
  preFetchBlocks,
  setSilhouetteVisibility,
  getEtchingLabelMssg,
  setBlockName,
};
