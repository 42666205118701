import React, { PureComponent, useState } from 'react';
import queryString from 'query-string';
import { Alert, Spin, Button, Timeline, Modal, Radio, Popconfirm } from 'antd';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { APP_THREEKIT_API } from '../../constants';
import { CenterVert } from '../../components/shared';
import {
  saveSpreadsheetDataToPlatform,
  migrateAssetToProd,
  saveFileIdToDevAsset,
  saveFileIdToProdAsset,
} from '../../modules/utils/app';

const saveSpreadsheet = {
  title: 'Prase and upload Spreadsheet Data to Platform',
  handler: saveSpreadsheetDataToPlatform,
};

const updateDevBaseAsset = {
  title: 'Update Spreadsheet Data access id of dev base asset',
  handler: saveFileIdToDevAsset,
};

const updateProdBaseAsset = {
  title: 'Update Spreadsheet Data access id of production base asset',
  handler: saveFileIdToProdAsset,
};

const assetMigration = {
  title: 'Migrate assets from dev to prod environment',
  handler: migrateAssetToProd,
};

const migrationSteps = [
  {
    label: 'Full Migration',
    steps: [saveSpreadsheet, updateDevBaseAsset, assetMigration],
    description:
      'Full migration will migrate both spreadsheet data and asset from dev environment to the production evnironment. The migration will take a much longer time compare with Spreadsheet Data Migration. It should be applied when the asset status changed!',
  },
  {
    label: 'Spreadsheet Data Migration',
    steps: [saveSpreadsheet, updateDevBaseAsset, updateProdBaseAsset],
    description:
      'Spreadsheet Data Migration will only migrate spreadsheet data to the production evnironment and keep the asset unchanged. This approach is much faster but should be applied in the case that no mismatch between dev and production asset. For example, update an instruments description.',
    warning:
      'You are going to process Spreadsheet Data Migration. Please note this migration may cause the configuration crash if there are mismatch between dev asset and production asset.',
  },
].map((step, idx) => {
  step.value = idx;
  return step;
});

const defaultMigrationState = {
  migrateStep: -1,
  errorStep: -1,
  errorMessage: '',
};

const RenderErrorPage = () => {
  return (
    <CenterVert style={{ marginLeft: '25%', width: '50%' }}>
      <Alert
        message="Error"
        description="Invalid authentication, please contact Threekit for support."
        type="error"
      />
    </CenterVert>
  );
};

const RenderLoadingPage = () => {
  return (
    <CenterVert style={{ marginLeft: '25%', width: '50%' }}>
      <Alert
        message="loading admin page..."
        showIcon
        icon={<Spin style={{ marginRight: 20 }} />}
      />
    </CenterVert>
  );
};

const RenderMainPage = ({ migrateStep, errorStep, errorMessage, setState }) => {
  const [approachIdx, setApproachIdx] = useState(0);
  const { steps, description, warning } = migrationSteps[approachIdx];

  const isButtonDisabled = () => {
    if (errorMessage) return false;
    else return migrateStep !== -1 && migrateStep !== steps.length;
  };

  const confirmMigration = () =>
    Modal.confirm({
      centered: true,
      title: 'Please confirm you want to start migration process!',
      icon: <ExclamationCircleOutlined />,
      content: warning,
      onOk: () => {
        onMigration();
        return Promise.resolve();
      },
    });

  const onMigration = async () => {
    setState(defaultMigrationState);

    const migrationResult = [];

    for (let idx = 0; idx < steps.length; ++idx) {
      try {
        setState({ migrateStep: idx });
        migrationResult[idx] = await steps[idx].handler(migrationResult, idx);
      } catch (e) {
        const errorMessage = e.toString();
        Modal.error({
          title: 'Migration Error',
          content: errorMessage,
          centered: true,
        });
        return setState({ errorStep: idx, errorMessage });
      }
    }
    setState({ migrateStep: steps.length });
    Modal.success({
      content: 'Migration Success',
      centered: true,
    });
  };

  return (
    <CenterVert style={{ marginLeft: '25%', width: '50%' }}>
      <Radio.Group defaultValue={approachIdx} style={{ marginBottom: '20px' }}>
        {migrationSteps.map((step) => (
          <Radio.Button
            value={step.value}
            key={step.value}
            onClick={(e) => setApproachIdx(e.target.value)}
            style={{ marginRight: '10px' }}
          >
            {step.label}
          </Radio.Button>
        ))}
      </Radio.Group>

      {description && (
        <Alert
          message={description}
          type="info"
          showIcon
          style={{ marginBottom: '20px' }}
        />
      )}

      <Timeline>
        {steps.map(({ title }, idx) => (
          <Timeline.Item
            key={title}
            color={
              errorStep === idx
                ? 'red'
                : idx > migrateStep
                  ? 'gray'
                  : idx < migrateStep
                    ? 'green'
                    : 'blue'
            }
            dot={
              errorStep !== idx && idx === migrateStep ? (
                <LoadingOutlined style={{ backgroundColor: '#f4f4f4' }} />
              ) : (
                undefined
              )
            }
          >
            <p>{title}</p>
          </Timeline.Item>
        ))}
      </Timeline>

      <Button onClick={confirmMigration} disabled={isButtonDisabled()}>
        Start Migration
      </Button>
    </CenterVert>
  );
};

export default class AdminPage extends PureComponent {
  constructor(props) {
    super(props);

    const search = queryString.parse(window.location.search);
    const { appid } = search;

    this.appid = appid;

    this.state = {
      ...defaultMigrationState,
      loadingStatus: appid ? 'loading' : 'error',
    };
  }

  async componentDidMount() {
    const { appid } = this;
    if (!appid) return;

    fetch(`${APP_THREEKIT_API}/app/${appid}`)
      .then((res) => {
        if (res.status === 404) throw new Error();
        return res.json();
      })
      .then((json) => {
        if (json.name !== 'Brasseler Admin') throw new Error();
        this.setState({ loadingStatus: 'loaded' });
      })
      .catch(() => this.setState({ loadingStatus: 'error' }));
  }

  render() {
    const { loadingStatus } = this.state;
    if (loadingStatus === 'error') return <RenderErrorPage />;
    else if (loadingStatus === 'loading') return <RenderLoadingPage />;
    else
      return (
        <RenderMainPage {...this.state} setState={this.setState.bind(this)} />
      );
  }
}
