import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../modules';

export default (Component) => {
  const mapDispatchToProps = {
    handleContinue: actions.ui.goToNextSection,
  };

  return connect(
    null,
    mapDispatchToProps
  )((props) => <Component {...props} />);
};
