import styled from 'styled-components';

import { CenterVert, Title } from '../shared/shared.styles';

const COLORS = {
  Blue: 'blue',
  Gold: 'gold',
  Red: 'red',
  Black: 'black',
  Purple: 'purple',
};

export const SelectedBlockWrapper = styled.div`
  background: white;
  border-radius: 3px;
  padding: 8px 8px 0px 8px;
  height: 100%;
  position: relative;
`;

export const BlockImage = styled(CenterVert)`
  height: 360px;
  width: 360px;

  img {
    height: 100%;
    width: 100%;
  }
`;

export const ConfigTitle = styled(Title)`
  margin-bottom: 10px;
`;

export const ConfigWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ColorSelect = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 15px;
`;

export const ColorRadio = styled.div`
  height: 50px;
  width: 50px;
  padding: 4px;
  margin-left: 8px;
  border: ${(props) =>
    props.selected ? '1px solid #00359c' : '1px solid white'};
  border-radius: 2px;
  cursor: pointer;

  & > div {
    height: 100%;
    width: 100%;
    background: ${(props) => COLORS[props.color]};
    border-radius: 2px;
  }

  &:hover {
    border: 1px solid #00359c;
  }
`;
