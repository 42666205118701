import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import Rollbar from 'rollbar';
import queryString from 'query-string';

import Page from './pages';
import createStore from './store';
import { ROLLBAR_TOKEN, UI_FLOW, UI_SECTIONS } from './constants';

export default class App extends PureComponent {
  constructor(props) {
    super(props);
    const { REACT_APP_ENV, REACT_APP_PARENT_ORIGIN } = process.env;
    const env =
      REACT_APP_ENV === 'PROD'
        ? 'production'
        : REACT_APP_ENV === 'DEV'
          ? 'dev'
          : undefined;
    const parentOrigin = REACT_APP_PARENT_ORIGIN || '*';

    const rollbar =
      env &&
      new Rollbar({
        accessToken: ROLLBAR_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: { environment: env },
      });
    const search = queryString.parse(window.location.search);
    const flow = UI_FLOW[this.props.flow] || UI_FLOW.blank;
    const section = UI_SECTIONS[flow] && UI_SECTIONS[flow][0];

    const initialReduxState = {
      order: {
        presetCode: this.props.match.params.presetCode,
        viewCode: this.props.match.params.viewCode,
      },
      app: {
        env,
        rollbar,
        parentOrigin,
        country: (search.country || 'us').toLocaleLowerCase(),
        year: search.year || String(new Date().getFullYear()),
        trackingNumber: search.trackingNumber || '',
      },
      ui: { flow, section },
    };
    this.store = createStore(initialReduxState);
  }

  render() {
    return (
      <Provider store={this.store}>
        <Page />
      </Provider>
    );
  }
}
