import React from 'react';
import { connect } from 'react-redux';
import { actions, selectors } from '../modules';
import { SET_SUBMIT_STATUS } from '../modules/utils/actionType';

export default (Component) => {
  const mapDispatchToProps = {
    getProcedure: selectors.ui.getSelectedProcedure,
    getSteps: selectors.ui.getProcedureStepNames,
    getInstruments: selectors.ui.getInstrumentArrangement,
    handleClickBack: actions.ui.goToPreviousSection,
    postMessageToParent: actions.app.postMessageToParent,
    setSection: actions.ui.goToSection,
    deleteInstruments: actions.config.deleteInstruments,
    setSubmitStatus: (status = {}) => ({
      type: SET_SUBMIT_STATUS,
      payload: status,
    }),
    saveConfigurator: actions.order.saveConfigurator,
    fetchConfigurator: actions.order.fetchConfigurator,
    getConfiguratorResumeLink: selectors.order.getConfiguratorResumeLink,
    getShareLink: selectors.order.getShareLink,
  };

  //  The state props are used only to trigger the summary
  //  to reload when the block/instruments are changed
  const mapStateToProps = ({ config, order, ui }) => ({
    configBlock: config.block,
    configInstruments: config.instruments, // the component did use this state, however, it is necessary to trigger the component rerender when instrument changes
    submitStatus: order.submitStatus,
    uuid: order.uuid,
    savedId: order.savedId,
    flow: ui.flow,
  });

  const WrappedComponent = (props) => <Component {...props} />;

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WrappedComponent);
};
