import { UI_SECTIONS } from '../../constants';
import { SET_PROCEDURE, SET_SECTION, SET_STEP } from '../utils/actionType';
import metadataSelectors from '../selectors/metadata';
import configActions from '../actions/config';
const { setBlock, deleteInstruments } = configActions;
const {
  getProcedureByName,
  getBlocksByProductLabel,
  getBlockByProductNumber,
  getBlockLabelByLabel,
} = metadataSelectors;

const setProcedure = procedureName => (dispatch, getState) => {
  const { config, ui } = getState();
  const { block } = config;
  const { selectedProcedure } = ui;
  if (procedureName === selectedProcedure) return;
  const procedure = dispatch(getProcedureByName(procedureName));
  const { blocks, recommendedBlocks } = procedure;

  const currentBlock = dispatch(getBlockByProductNumber(block.productNumber));

  // change block if current block is empty or current block is not valid for new procedure
  if (!currentBlock || !blocks.includes(currentBlock.productLabel)) {
    const newBlockLabel =
      recommendedBlocks.find(
        blockLabel => !!dispatch(getBlockLabelByLabel(blockLabel)).assetId
      ) ||
      blocks.find(
        blockLabel => !!dispatch(getBlockLabelByLabel(blockLabel)).assetId
      );
    if (!newBlockLabel)
      throw new Error(
        `Set Procedure Error! No Valid block for procedure ${procedureName}!`
      );
    const newBlock = dispatch(getBlocksByProductLabel(newBlockLabel))[0];
    dispatch(setBlock({ productNumber: newBlock.productNumber }));
  }
  dispatch(deleteInstruments());
  return dispatch({ type: SET_PROCEDURE, payload: procedureName });
};

const setStep = step => ({
  type: SET_STEP,
  payload: step,
});

const goToSection = (direction = null) => section => async (
  dispatch,
  getState
) => {
  const { ui } = getState();
  const { flow, section: idx } = ui;
  const sections = UI_SECTIONS[flow];
  let nextSection;

  if (direction) {
    let shift = direction === 'next' ? 1 : -1;
    nextSection = sections[sections.indexOf(idx) + shift];
  } else if (
    typeof section === 'string' &&
    UI_SECTIONS[flow].includes(section)
  ) {
    nextSection = section;
  }

  return dispatch({ type: SET_SECTION, payload: nextSection });
};

export default {
  setProcedure,
  setStep,
  goToSection: goToSection(),
  goToNextSection: goToSection('next'),
  goToPreviousSection: goToSection('previous'),
};
