import React, { useState } from 'react';
import {
  Wrapper,
  Primary,
  Secondary,
  Image,
  Buttons,
  ItemBtn,
  SecondaryText,
} from './items.styles';
import { CenterVert } from '../shared';
import { Icon } from 'antd';
import iconNoImage from '../../assets/icons/no-image.webp';

export default ({
  thumbnailUrl,
  modelId,
  label,
  productLabel,
  productNumber,
  handleDelete,
  description,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClickExpand = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleDeleteItem = () => handleDelete(modelId);

  return (
    <Wrapper
      expand={isExpanded}
      size={thumbnailUrl ? 'large' : 'small'}
      style={{ cursor: 'default' }}
    >
      <Primary size={thumbnailUrl ? 'large' : 'small'}>
        <div>
          <Image expand={isExpanded}>
            <img
              src={thumbnailUrl}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = iconNoImage;
              }}
              alt="item"
            />
          </Image>
        </div>
        <CenterVert>{label || productLabel}</CenterVert>
        <CenterVert>
          <Buttons>
            {handleDelete && (
              <ItemBtn>
                <Icon type="delete" onClick={handleDeleteItem} />
              </ItemBtn>
            )}
            <ItemBtn onClick={handleClickExpand} expand={isExpanded}>
              <Icon type="down" />
            </ItemBtn>
          </Buttons>
        </CenterVert>
      </Primary>
      {isExpanded && (
        <Secondary>
          <SecondaryText>{description}</SecondaryText>
          <SecondaryText>{productNumber}</SecondaryText>
        </Secondary>
      )}
    </Wrapper>
  );
};
