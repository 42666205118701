import metadataSelectors from './metadata';
const {
  getInstrumentsFromConfigState,
  getInstrumentAttributeValues,
  getProcedureByName,
  getProcedureStepByName,
  getBlockAttributeValues,
  getBlockLabelsFromConfigState,
  getProductSheetPropertyData,
  getSettingByName,
} = metadataSelectors;

const getSelectedProcedure = () => (dispatch, getState) => {
  const { ui } = getState();
  return dispatch(getProcedureByName(ui.selectedProcedure));
};

const getSelectedStep = () => (dispatch, getState) => {
  const { ui } = getState();
  return dispatch(getProcedureStepByName(ui.selectedStep));
};

const getInstrumentArrangement = () => (_, getState) => {
  const { config } = getState();
  const { instruments } = config;
  let output = {};

  // If there are no instruments in the state we return null
  if (Object.entries(instruments).length === 0) {
    return null;
  }

  Object.values(instruments).forEach((instrument) => {
    output[instrument.step]
      ? output[instrument.step].push(instrument)
      : (output[instrument.step] = [instrument]);
  });
  return output;
};

const getInstrumentFilter = (optionalInstruments) => (dispatch) => {
  const instruments =
    optionalInstruments || dispatch(getInstrumentsFromConfigState());

  const insturmentLabelProperties = dispatch(
    getProductSheetPropertyData('Instruments')
  );
  const filterCategorys = dispatch(getSettingByName('instrumentFilter')).value;
  const filterTitles = filterCategorys.map(
    (title) => insturmentLabelProperties[title].label
  );
  const filters = filterCategorys.reduce((filters, property) => {
    const options = dispatch(
      getInstrumentAttributeValues(property, instruments)
    );
    if (!options.length) return filters;
    return Object.assign(filters, {
      [property]: options.reduce(
        (options, value) => Object.assign(options, { [value]: false }),
        {}
      ),
    });
  }, {});

  return { filterTitles, filters };
};

const getBlockFilter = (optionalBlocks) => (dispatch) => {
  let blocks = optionalBlocks || dispatch(getBlockLabelsFromConfigState());

  if (!blocks) return {};
  const blockLabelProperties = dispatch(
    getProductSheetPropertyData('BlockLabels')
  );
  const filterCategorys = dispatch(getSettingByName('blockLabelFilter')).value;
  const filterTitles = filterCategorys.map(
    (title) => blockLabelProperties[title].label
  );
  const filters = filterCategorys.reduce((filters, property) => {
    const options = dispatch(getBlockAttributeValues(property, blocks));
    if (!options.length) return filters;
    return Object.assign(filters, {
      [property]: options.reduce(
        (options, value) => Object.assign(options, { [value]: false }),
        {}
      ),
    });
  }, {});
  return { filters, filterTitles };
};

const getProcedureStepNames = (procedureName) => (dispatch, getState) => {
  const { ui } = getState();
  const procedure = procedureName || ui.selectedProcedure;
  if (!procedure) return {};
  const stepList = dispatch(getProcedureByName(procedure)).steps;

  return stepList.reduce((steps, step) => {
    steps[step] = dispatch(getProcedureStepByName(step)).label;
    return steps;
  }, {});
};

export default {
  getSelectedProcedure,
  getSelectedStep,
  getInstrumentArrangement,
  getInstrumentFilter,
  getBlockFilter,
  getProcedureStepNames,
};
