import styled from 'styled-components';
import {
  SECTION_MARGIN,
  SMALL_SECTION_HEADER_HEIGHT,
  LARGE_SECTION_HEADER_HEIGHT,
} from '../shared.styles';

export const ChildWrapper = styled.div`
  padding-left: 10px;
  min-width: 180px;
  display: flex;
  justify-content: flex-end;
`;

export const Primary = styled.div`
  height: ${SMALL_SECTION_HEADER_HEIGHT}PX;
  display: flex;
  justify-content: ${(props) => (props.withChild ? 'space-between' : 'center')};
  align-items: center;
  padding: 0 5px;
`;

export const Wrapper = styled.div`
  height: ${(props) =>
    `${
      props.SecondaryChildren
        ? LARGE_SECTION_HEADER_HEIGHT
        : SMALL_SECTION_HEADER_HEIGHT
    }px`};
  margin-bottom: ${SECTION_MARGIN}px;
  color: rgba(0, 0, 0, 0.4);
`;
export const Secondary = styled.div`
  height: ${LARGE_SECTION_HEADER_HEIGHT - SMALL_SECTION_HEADER_HEIGHT}px;
`;
