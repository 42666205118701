import React, { useState, useEffect, Fragment } from 'react';
import {
  SectionHeading,
  SectionBody,
  SectionFooter,
  Title,
  Subtitle,
  CenterVert,
} from '../shared';
// import SelectedBlock from '../Items/SelectedBlock';
import {
  SelectedBlockWrapper,
  BlockImage as Image,
  ConfigTitle,
  ConfigWrapper,
  ColorSelect,
  ColorRadio,
} from './block.styles';
import container from '../../containers/blockSelectedContainer';

const BlockSelectedHeading = () => (
  <SectionHeading title="Configure Your Block" />
);

const SelectedBlock = ({ blocks, selected, onChange }) => {
  const block =
    blocks && blocks.find((block) => block.productNumber === selected);

  if (!block) return <div />;
  return (
    <SelectedBlockWrapper>
      <Title>{block.productLabel}</Title>
      <Subtitle>{block.productNumber}</Subtitle>
      <Subtitle>{block.description}</Subtitle>
      <Image>
        <img src={block.thumbnailUrl} alt="burr-block" />
      </Image>
      <ConfigTitle>Configuration</ConfigTitle>
      <ConfigWrapper>
        <CenterVert>Color:</CenterVert>
        <CenterVert>
          <ColorSelect>
            {blocks.map((block, i) => (
              <ColorRadio
                key={block.productNumber}
                color={block.color}
                onClick={() => onChange(block.productNumber)}
                selected={selected === block.productNumber}
              >
                <div />
              </ColorRadio>
            ))}
          </ColorSelect>
        </CenterVert>
      </ConfigWrapper>
    </SelectedBlockWrapper>
  );
};

const BlockSelected = ({
  blockLabel,
  handleContinue, //  handles continue to next section
  handleBack, //  handles back to previous section (section change only)
  configBlock,
  setBlock, //  function should return the 'product number' of the block to be selected
  getBlocksByProductLabel, //  Returns all blocks with the same label from redux state
}) => {
  //  An array of blocks that have the same block label
  const [blockOptions, setBlockOptions] = useState(
    blockLabel && getBlocksByProductLabel(blockLabel)
  );

  const [preselectedBlock, setPreselectedBlock] = useState(
    configBlock || (blockOptions && blockOptions[0].productNumber)
  );

  useEffect(
    () => {
      if (!blockLabel) return;
      const newBlockOptions = getBlocksByProductLabel(blockLabel);
      setBlockOptions(newBlockOptions);
      if (
        !newBlockOptions.find(
          ({ productNumber }) => productNumber === preselectedBlock
        )
      )
        setPreselectedBlock(newBlockOptions[0].productNumber);
    },
    [blockLabel]
  );

  const handlePreselectBlock = (productNumber) =>
    setPreselectedBlock(productNumber);

  const handleSelectBlock = async () => {
    await setBlock({ productNumber: preselectedBlock });
    handleContinue();
  };
  return (
    <Fragment>
      <BlockSelectedHeading />
      <SectionBody>
        <SelectedBlock
          blocks={blockOptions}
          selected={preselectedBlock}
          onChange={handlePreselectBlock}
        />
      </SectionBody>
      <SectionFooter
        handleBack={handleBack}
        handleContinue={handleSelectBlock}
        backLabel="Change Block"
      />
    </Fragment>
  );
};

export default container(BlockSelected);
