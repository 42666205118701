import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import App from './App';
import AdminPage from './pages/adminPage';
import './index.css';
import * as serviceWorker from './serviceWorker';

import { UI_FLOW } from './constants';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/order/:orderId" component={App} />
      <Route
        exact
        path="/view/:viewCode"
        render={(props) => <App {...props} flow={UI_FLOW.view} />}
      />
      <Route
        exact
        path={['/procedure', '/procedure/:presetCode']}
        render={(props) => <App {...props} flow={UI_FLOW.procedure} />}
      />
      <Route
        exact
        path={['/blank', '/blank/:presetCode']}
        render={(props) => <App {...props} flow={UI_FLOW.blank} />}
      />
      <Route exact path="/admin" render={(props) => <AdminPage {...props} />} />
      <Route exact path="*" component={App} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('threekit-root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
