import styled from 'styled-components';
import { TRANSITION_DURATION } from '../shared.styles';

export const Wrapper = styled.div`
  border: 1px solid ${(props) => (props.selected ? '#00359c' : '#e1e1e1')};
  border-radius: 20px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-left: 10px;

  background: ${(props) => (props.selected ? '#00359c' : 'white')};
  transition: all ${TRANSITION_DURATION};

  & > div {
    text-align: center;
    width: max-content;
    min-width: 70px;
    padding: 8px 15px;
    font-size: 15px;
    text-transform: capitalize;
    cursor: pointer;
    color: ${(props) => (props.selected ? '#fff' : '#000')};

    transition: all ${TRANSITION_DURATION};
  }
`;
