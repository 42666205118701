import React, { PureComponent, Fragment } from 'react';
import Filter from '../Filter';
import Item from '../Items/SelectorItem';
import { Button, Input, message } from 'antd';
import {
  SectionFooter,
  SectionHeading,
  SectionBody,
  FilterButton,
} from '../shared';
import { getOccipiedHole } from '../../modules/utils/config';
import { convertFiltersToQueryObj } from '../../modules/utils/metadata';
import container from '../../containers/instrumentSelectorContainer';
import { SEARCH } from '../../constants';
import { SectionSecondary as RotatoryFilterWrapper } from '../Filter/filter.styles';

const { Search } = Input;

const RotatoryFilter = ({ filterLabel, filterOptions, onClick }) => (
  <RotatoryFilterWrapper
    style={{ padding: '0px', overflowX: 'auto', flexWrap: 'unset' }}
  >
    {Object.entries(filterOptions).map(([option, value]) => (
      <FilterButton
        key={option}
        option={option}
        selected={value}
        filter={filterLabel}
        onClick={onClick}
      />
    ))}
  </RotatoryFilterWrapper>
);

//  COMPONENTS FOR: Instrument Selector
const InstrumentSelectorHeading = ({
  handleClick,
  handleSearch,
  count,
  secondaryChildren,
  secondaryChildrenProps,
}) => (
  <SectionHeading
    title="Select Instruments"
    SecondaryChildren={secondaryChildren}
    SecondaryProps={secondaryChildrenProps}
  >
    <Search
      size="small"
      onChange={handleSearch}
      // onSearch={handleSearch}
      style={{ width: 120, marginRight: 20 }}
    />
    <Button size="small" onClick={handleClick}>
      Filter {count && count}
    </Button>
  </SectionHeading>
);

const InstrumentSelectorList = ({
  instruments,
  selectedInstruments,
  handlePreselect,
  onHover,
  onMouseOut,
}) => (
  <SectionBody withWrapper headerSize="large">
    {instruments.map((instrument) => {
      const { productNumber, holeIdx } = instrument;
      const selected = selectedInstruments[productNumber];
      const isDisabled = !holeIdx && !selected;
      return (
        <Item
          {...instrument}
          disabled={isDisabled}
          key={productNumber}
          onSelect={() => !isDisabled && handlePreselect(productNumber)}
          selected={selected}
          onHover={onHover}
          onMouseOut={onMouseOut}
        />
      );
    })}
  </SectionBody>
);

class InstrumentSelector extends PureComponent {
  constructor(props) {
    super(props);
    const { getUIElementByName } = props;
    const {
      filters,
      filterTitles,
      instrumentList,
      headInstrumentList,
      headMaterialFilter,
      validedInstruments,
    } = this.initialFromBlock(props);

    this.state = {
      localInstruments: {},
      instrumentList,
      showFilters: false,
      filters,
      headMaterialFilter,
      headInstrumentList,
      validedInstruments,
      filterCount: 0,
      searchValue: '',
      prevFilter: { ...filters },
    };
    this.filterTitles = filterTitles;
    this.dragHintMessage = getUIElementByName('instrumentDragHint').label;
  }

  initialFromBlock = (props) => {
    const {
      getInstrumentFilter,
      getAvailableInstruments,
      configInstruments,
      validateInstruments,
      getInstrumentAttributeValues,
    } = props || this.props;

    const { filters, filterTitles } = getInstrumentFilter();
    const availableInstruments = getAvailableInstruments();
    const instrumentList = validateInstruments(
      availableInstruments,
      configInstruments
    );
    const headInstrumentList = [...instrumentList];
    const headMaterialFilter = getInstrumentAttributeValues(
      'headMaterial',
      instrumentList
    ).reduce((filter, value) => Object.assign(filter, { [value]: true }), {});
    return {
      filters,
      filterTitles,
      instrumentList,
      headInstrumentList,
      headMaterialFilter,
      validedInstruments: this.getValidInstrumentsFromArray(instrumentList),
    };
  };

  getValidInstrumentsFromArray = (validArray) =>
    validArray.reduce(
      (valid, instrument) =>
        Object.assign(valid, { [instrument.productNumber]: instrument }),
      {}
    );

  validMissingInstruments = (newState = {}) => {
    const { validateInstruments, configInstruments } = this.props;
    const { validedInstruments } = this.state;
    const instrumentList = newState.instrumentList || this.state.instrumentList;
    const localInstruments =
      newState.localInstruments || this.state.localInstruments;

    const remainValidList = instrumentList.filter(
      (instrument) => !validedInstruments[instrument.productNumber]
    );
    if (!remainValidList.length) return;

    const remainValidedInstruments = validateInstruments(remainValidList, {
      ...configInstruments,
      ...localInstruments,
    });
    const newValidedInstruments = {
      ...validedInstruments,
      ...this.getValidInstrumentsFromArray(remainValidedInstruments),
    };

    const newInstrumentList = instrumentList.map(
      (instrument) => newValidedInstruments[instrument.productNumber]
    );
    return {
      validedInstruments: newValidedInstruments,
      instrumentList: newInstrumentList,
    };
  };

  componentWillReceiveProps(newProps) {
    const { configInstruments, block } = this.props;
    const { localInstruments } = this.state;
    if (configInstruments !== newProps.configInstruments) {
      // update preselect instrument state when configInsturments change
      // user may preselect a instrument, but later move an existing instrument to the same spot
      // The config Instrument change will tigger the change of localInstruments, thus trigger
      // the useEffect specific to localInstruments below
      const localInstrumentKey = Object.keys(localInstruments);
      if (!localInstrumentKey.length) return;
      const occipiedHole = getOccipiedHole(
        block.holeData,
        newProps.configInstruments
      );
      const newLocalInstruments = localInstrumentKey.reduce(
        (instruments, productNumber) => {
          const { holeShank, holeIdx } = localInstruments[productNumber];
          if (occipiedHole[holeShank][holeIdx]) return instruments;
          return Object.assign(instruments, {
            [productNumber]: localInstruments[productNumber],
          });
        },
        {}
      );
      this.setState({ localInstruments: newLocalInstruments });
    }

    if (block.productNumber !== newProps.block.productNumber) {
      const {
        filters,
        filterTitles,
        instrumentList,
        headInstrumentList,
        headMaterialFilter,
        validedInstruments,
      } = this.initialFromBlock();

      this.setState({
        instrumentList,
        localInstruments: {},
        filters,
        filterCount: 0,
        searchValue: '',
        validedInstruments,
        headInstrumentList,
        headMaterialFilter,
        prevFilter: { ...filters },
      });
      this.filterTitles = filterTitles;
    }
  }

  componentWillUpdate(newProps, newState) {
    const { configInstruments, validateInstruments } = this.props;
    const { localInstruments } = this.state;
    if (
      configInstruments !== newProps.configInstruments ||
      localInstruments !== newState.localInstruments
    ) {
      // update instruement validation when config or local instruments state change
      const validInstrumentArray = validateInstruments(
        newState.instrumentList,
        {
          ...newProps.configInstruments,
          ...newState.localInstruments,
        }
      );
      this.setState({
        instrumentList: validInstrumentArray,
        validedInstruments: this.getValidInstrumentsFromArray(
          validInstrumentArray
        ),
      });
    }
  }

  handleInstrumentFilter = (
    optionalFilters,
    optionalSearch,
    optionalInstrumentList
  ) => {
    const { getInstruments } = this.props;
    const {
      localInstruments,
      validedInstruments,
      filters,
      searchValue,
      headInstrumentList,
    } = this.state;
    const search =
      optionalSearch === undefined || optionalSearch === null
        ? searchValue
        : optionalSearch;
    const query = convertFiltersToQueryObj(optionalFilters || filters);
    const count = Object.values(query).reduce(
      (count, queryValue) => count + queryValue.length,
      0
    );
    const newInstrumentList = getInstruments(
      query,
      optionalInstrumentList || headInstrumentList
    ).filter((instrument) => {
      for (let field of SEARCH.instruments) {
        if (instrument[field].toLowerCase().includes(search.toLowerCase()))
          return true;
      }
    });
    const newState = { instrumentList: newInstrumentList, filterCount: count };

    const newInstrumentsMap = new Set(
      newInstrumentList.map(({ productNumber }) => productNumber)
    );
    let localInstrumentChanged = false;
    const newLocalInstruments = Object.keys(localInstruments).reduce(
      (instruments, productNumber) => {
        if (newInstrumentsMap.has(productNumber))
          instruments[productNumber] = localInstruments[productNumber];
        else localInstrumentChanged = true;
        return instruments;
      },
      {}
    );
    if (localInstrumentChanged) {
      // set localInstruments only if it changes as it will trigger instrument re-validate
      newState.localInstruments = newLocalInstruments;
    } else {
      // check if any instrument in the new list has not valid yet
      // with will happen if user change filter or search that result to more instrument
      const validInstrumentState = this.validMissingInstruments(newState);
      // if validInstrumentState is not undefined, means additional instruments has been validate
      // so we need to update the state. else, mean all the new instrumentList has already been validated
      // and the validation is saved in validedInstruments, so we simply read the data from it
      if (validInstrumentState) Object.assign(newState, validInstrumentState);
      else
        newState.instrumentList = newInstrumentList.map(
          ({ productNumber }) => validedInstruments[productNumber]
        );
    }
    return newState;
  };

  handleToggleFilters = () => {
    const { showFilters, filters } = this.state;
    const newState = {};
    if (showFilters) Object.assign(newState, this.handleInstrumentFilter());
    newState.showFilters = !showFilters;
    newState.prevFilter = { ...filters };
    this.setState(newState);
  };

  isFilterChanged = () => {
    const { filters, prevFilter } = this.state;
    for (let field in filters) {
      for (let value in filters[field]) {
        if (!prevFilter[field]) continue;
        if (filters[field][value] !== prevFilter[field][value]) return true;
      }
    }
    return false;
  };

  handleSearch = (e) => {
    const value = e.target.value;
    const newState = this.handleInstrumentFilter(null, value);
    newState.searchValue = value;
    this.setState(newState);
  };

  handlePreselect = (productNumber) => {
    const { localInstruments, instrumentList } = this.state;
    const newLocalInstruments = { ...localInstruments };

    if (newLocalInstruments[productNumber]) {
      delete newLocalInstruments[productNumber];
    } else {
      newLocalInstruments[productNumber] = instrumentList.find(
        (instrument) => instrument.productNumber === productNumber
      );
    }
    this.setState({ localInstruments: newLocalInstruments });
  };
  handleClickConfirm = async () => {
    const { addInstruments, handleContinue } = this.props;
    const { localInstruments } = this.state;
    await addInstruments(Object.values(localInstruments));
    this.setState({ localInstruments: {} });
    message.success(this.dragHintMessage, 7);
    handleContinue();
  };

  handleApplyFilters = (filters) => this.setState({ filters });

  handleClearFilters = () => {
    const { getInstrumentFilter } = this.props;
    const { headInstrumentList } = this.state;
    this.setState({
      filters: getInstrumentFilter(headInstrumentList).filters,
    });
  };
  handleHover = (productNumber) => {
    const { block, setAnnotation } = this.props;
    const { localInstruments, instrumentList } = this.state;

    const { holeIdx, holeShank } =
      localInstruments[productNumber] ||
      instrumentList.find(
        (instrument) => instrument.productNumber === productNumber
      );

    if (!holeIdx || !holeShank) return;
    const holeData = block.holeData[holeShank][holeIdx];
    setAnnotation(holeData);
  };

  onHeadMaterialFilterClick = (filter, option) => {
    const {
      getAvailableInstruments,
      getInstruments,
      getInstrumentFilter,
    } = this.props;
    const { headMaterialFilter } = this.state;
    const newHeadMaterialFilter = {
      ...headMaterialFilter,
      [option]: !headMaterialFilter[option],
    };
    const headFilter = Object.keys(newHeadMaterialFilter).reduce(
      (filterMap, option) => {
        if (newHeadMaterialFilter[option]) {
          filterMap[option] = true;
        }
        return filterMap;
      },
      { Extra: true }
    );
    const query = convertFiltersToQueryObj({ [filter]: headFilter });
    const newHeadInstrumentList = getInstruments(
      query,
      getAvailableInstruments()
    );
    const newFilters = getInstrumentFilter(newHeadInstrumentList).filters;
    const newState = this.handleInstrumentFilter(
      newFilters,
      null,
      newHeadInstrumentList
    );
    this.setState({
      ...newState,
      filters: newFilters,
      headMaterialFilter: newHeadMaterialFilter,
      headInstrumentList: newHeadInstrumentList,
    });
  };

  render() {
    const {
      hideAnnotation,
      handleBack,
      handleNextSection,
      configInstruments,
      handleContinue,
    } = this.props;
    const {
      handleToggleFilters,
      handlePreselect,
      handleHover,
      handleClickConfirm,
      handleApplyFilters,
      handleClearFilters,
      handleSearch,
    } = this;
    const {
      instrumentList,
      localInstruments,
      filterCount,
      showFilters,
      filters,
      headMaterialFilter,
      headInstrumentList,
    } = this.state;
    const hasLocalInstruments = !!Object.keys(localInstruments).length;
    const hasConfigInstruments = !!Object.keys(configInstruments).length;
    let continueLabel, handleNext;
    if (hasLocalInstruments) {
      continueLabel = 'Confirm Selection';
      handleNext = handleClickConfirm;
    } else if (hasConfigInstruments) {
      continueLabel = 'Continue';
      handleNext = handleContinue;
    } else {
      continueLabel = 'Continue Without Instrument';
      handleNext = handleNextSection;
    }
    return (
      <Fragment>
        <InstrumentSelectorHeading
          handleClick={handleToggleFilters}
          handleSearch={handleSearch}
          count={filterCount > 0 && filterCount}
          secondaryChildren={RotatoryFilter}
          secondaryChildrenProps={{
            filterLabel: 'headMaterial',
            filterOptions: headMaterialFilter,
            onClick: this.onHeadMaterialFilterClick,
          }}
        />
        <InstrumentSelectorList
          instruments={instrumentList}
          selectedInstruments={localInstruments}
          handlePreselect={handlePreselect}
          onHover={handleHover}
          onMouseOut={hideAnnotation}
        />
        <SectionFooter
          handleBack={handleBack}
          handleContinue={handleNext}
          continueLabel={continueLabel}
        />

        <Filter
          show={showFilters}
          items="Instruments"
          total={headInstrumentList.length}
          handleCloseFilter={handleToggleFilters}
          filters={filters}
          setFilters={handleApplyFilters}
          clearFilters={handleClearFilters}
          disableApplyFilter={!this.isFilterChanged()}
          filterTitles={this.filterTitles}
          itemList={headInstrumentList}
        />
      </Fragment>
    );
  }
}

export default container(InstrumentSelector);
