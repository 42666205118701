import styled from 'styled-components';
import { Title } from '../shared';
import { ItemBtn } from '../Items/items.styles';

export const CustomizeTitle = styled(Title)`
  padding-left: 15px;
  text-align: left;
`;

export const Section = styled.div`
  margin-bottom: 28px;

  padding: 0px 15px;

  & > div:nth-child(1) {
    margin-bottom: 8px;
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 6px 8px;
  outline: none;
  resize: none;
  border-radius: 3px;
`;

export const Characters = styled.div`
  text-align: right;
  font-size: 12px;
`;

export const UploadInfo = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  margin-top: 8px;
  position: relative;
  width: 100%;
  & > img {
    width: 20px;
    height: 20px;
  }
  & > div {
    padding-left: 8px;
    display: flex;
  }
`;

export const CustomizeItemBtn = styled(ItemBtn)`
  position: absolute,
  right: 0px
`;
