import {
  BLOCK_ROOT,
  MM_TO_M,
  LID_ROOT,
  HOLDING,
  APP_LOGO_API,
} from '../../constants';
import {
  validInstrumentBasedOnLayout,
  validBlockBasedOnInstruments,
  sortInstrumentsByProperty,
  fetchBlockHoleData,
} from '../utils/config';
import { getAssetInstance } from '../utils/threekit';
import metadataSelectors from '../selectors/metadata';

const {
  getInstrumentsFromConfigState,
  getBlockLabelsFromConfigState,
} = metadataSelectors;

const getOverlayModelId = (modelId, translation) => async (_, getState) => {
  const state = getState();
  const { threekitApi } = state.threekit;
  const { block, instruments } = state.config;
  const { headSize } = instruments[modelId];
  const { holeData, movingBoundary } = block;
  const overlayModelIds = await Promise.all(
    Object.values(instruments)
      .filter((instrument) => {
        if (instrument.modelId === modelId) return false;
        const { holeShank, holeIdx } = instrument;
        if (!holeData[holeShank]) return false;
        const distance = holeData[holeShank][holeIdx].translation.distanceTo(
          translation
        );
        return distance < ((headSize + instrument.headSize) * MM_TO_M) / 2;
      })
      .map(async (instrument) => {
        const placeHolderInstance = await getAssetInstance(threekitApi, {
          id: instrument.modelId,
          plug: 'Null',
          property: 'asset',
        });
        return threekitApi.scene.findNode({
          from: placeHolderInstance,
          name: 'Instrument',
        });
      })
  );

  const movingInstrumentId = await getAssetInstance(threekitApi, {
    id: modelId,
    plug: 'Null',
    property: 'asset',
  });
  overlayModelIds.push(
    threekitApi.scene.findNode({
      from: movingInstrumentId,
      name: 'Instrument',
    })
  );

  if (Math.abs(translation.x) + (headSize * MM_TO_M) / 2 > movingBoundary.x) {
    const blockModelId = threekitApi.scene.findNode({ name: BLOCK_ROOT });
    const blockInstance = await getAssetInstance(threekitApi, {
      id: blockModelId,
      plug: 'Null',
      property: 'asset',
    });
    overlayModelIds.push(
      threekitApi.scene.findNode({ from: blockInstance, name: LID_ROOT })
    );
  }

  return overlayModelIds;
};

const filterInstrumentsFromConfigState = (
  optionalInstruments,
  optionalInstrumentState
) => (dispatch, getState) => {
  if (optionalInstruments && !Array.isArray(optionalInstruments))
    throw new Error(
      `optionalInstruments of filterInstrumentsFromConfigState must be array!`
    );

  const { config } = getState();
  const { block, instruments: configInstrumentState } = config;

  const instruments =
    optionalInstruments || dispatch(getInstrumentsFromConfigState());
  const instrumentState = optionalInstrumentState || configInstrumentState;
  return validInstrumentBasedOnLayout(block, instrumentState, instruments).map(
    (holeData, arrayIdx) => ({
      ...instruments[arrayIdx],
      ...holeData,
    })
  );
};

const filterBlockFromConfigState = (optionalBlocks) => async (
  dispatch,
  getState
) => {
  const inputBlock = !!optionalBlocks;
  const inputAsArray = Array.isArray(optionalBlocks);

  const { config, threekit } = getState();
  const { instruments: configInstrumentState } = config;
  const { threekitApi } = threekit;
  const blocks = inputBlock
    ? inputAsArray
      ? optionalBlocks
      : [optionalBlocks]
    : dispatch(getBlockLabelsFromConfigState());
  const sortedInstruments = sortInstrumentsByProperty(
    configInstrumentState,
    'holeShank',
    (ins1, ins2) => ins1.holeIdx - ins2.holeIdx
  );
  let results;
  if (!sortedInstruments) {
    results = blocks.map((block) => ({ ...block, instruments: [] }));
  } else {
    const holdingInstruments = {};
    if (sortedInstruments[HOLDING.shankName]) {
      sortedInstruments[HOLDING.shankName].reduce(
        (instruments, instrument) =>
          Object.assign(instruments, { [instrument.modelId]: instrument }),
        holdingInstruments
      );
      delete sortedInstruments[HOLDING.shankName];
    }

    results = await Promise.all(
      blocks.map(async (block) => {
        if (!block.assetId) return block;
        const blockHoleData = await fetchBlockHoleData(threekitApi, block);
        const newInstruments = validBlockBasedOnInstruments(
          { ...blockHoleData, blockData: block },
          sortedInstruments
        );
        return {
          ...block,
          instruments: newInstruments,
        };
      })
    );
  }
  return inputBlock && !inputAsArray ? results[0] : results;
};

const getLogoEndpoint = () => () => {
  return APP_LOGO_API;
};

export default {
  getOverlayModelId,
  filterInstrumentsFromConfigState,
  filterBlockFromConfigState,
  getLogoEndpoint,
};
