import styled from 'styled-components';
import {
  TRANSITION_DURATION,
  ACTION_BUTTON_HEIGHT,
} from '../shared/shared.styles';

const HEADER_HEIGHT = 60;
const SUMMARY_HEIGHT = 20;
const MARGIN_LARGE = 30;
const MARGIN_SMALL = 10;

/* ------------------------------------------------------------------ 
  Styles
------------------------------------------------------------------ */

export const Wrapper = styled.div`
  position: relative;
  background: #f4f4f4;
  height: 100%;
  width: 100%
  top: -100%;
  transform: ${(props) => (props.show ? 'translateX(0)' : 'translateX(120%)')};
  transition: all ${TRANSITION_DURATION};
  z-index: 15;
`;

export const Header = styled.div`
  height: ${HEADER_HEIGHT}px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  & > div:nth-child(1) {
    svg {
      font-size: 22px;
    }
  }

  & > div:nth-child(2) {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }

  & > div:nth-child(3) {
    text-align: right;
  }
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: ${SUMMARY_HEIGHT}px;
  margin: ${MARGIN_LARGE}px;
  margin-bottom: ${MARGIN_SMALL}px;
`;

const contentOffset =
  HEADER_HEIGHT +
  SUMMARY_HEIGHT +
  MARGIN_LARGE * 2 +
  MARGIN_SMALL * 2 +
  ACTION_BUTTON_HEIGHT;

export const Content = styled.div`
  margin: ${MARGIN_LARGE}px;
  margin-top: ${MARGIN_SMALL}px;
  display: flex;
  flex-direction: column;
  height: calc(100% - ${contentOffset}px);
  overflow-y: auto;
`;

export const Section = styled.div`
  transition: all ${TRANSITION_DURATION};
  background: white;
  border-radius: 3px;
  margin-bottom: 12px;
  padding: 0 16px;
`;

export const SectionPrimary = styled.div`
  height: 45px;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;

  display: grid;
  grid-template-columns: auto max-content;

  button {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    transform: ${(props) => (props.expand ? 'rotate(180deg)' : 'rotate(0deg)')};

    &:hover {
      color: #00359c;
    }

    transition: all ${TRANSITION_DURATION};
  }

  & > div > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const SectionSecondary = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 15px 0;
`;
