import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { SET_APP_HEIGHT } from '../../modules/utils/actionType';
import { Wrapper, PlayerWrapper, ControllerWrapper } from './configPage.styles';

//  Components
import Player from '../../components/Player';
import Controller from '../../components/Controller';

class ConfigPage extends PureComponent {
  componentDidMount() {
    this.setAppHeight();
  }

  componentWillMount() {
    window.addEventListener('resize', this.setAppHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setAppHeight);
  }

  setAppHeight = () => {
    this.props.setHeight(this.controllerWrapper.clientHeight);
  };

  render() {
    const { appHeight } = this.props;
    return (
      <Wrapper>
        <PlayerWrapper>
          <Player />
        </PlayerWrapper>
        <ControllerWrapper ref={(el) => (this.controllerWrapper = el)}>
          <Controller height={appHeight} />
        </ControllerWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ app }) => ({ appHeight: app.appHeight });
const mapDispatchToProps = {
  setHeight: (height) => ({ type: SET_APP_HEIGHT, payload: height }),
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigPage);
