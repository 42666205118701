import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../modules';

export default (Component) => {
  const mapStateToProps = (state) => ({
    flow: state.ui.flow,
    procedure: state.ui.selectedProcedure,
    configBlock: state.config.block.productNumber,
    configBlockData: state.config.block.blockData,
    configInstruments: state.config.instruments,
  });
  const mapDispatchToProps = {
    //  UI state handlers
    handleBack: actions.ui.goToPreviousSection,
    handleContinue: actions.ui.goToNextSection,
  };

  const WrappedComponent = (props) => <Component {...props} />;

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WrappedComponent);
};
