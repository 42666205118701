import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import reducer, { initialState as defaultState } from './modules';

const middleware = [thunk];

export default (initialState = {}) => {
  if (initialState.app.env !== 'production') middleware.push(logger);
  const enhancers = compose(applyMiddleware(...middleware));

  Object.keys(initialState).forEach(moduleName =>
    Object.assign(defaultState[moduleName], initialState[moduleName])
  );

  return createStore(reducer, defaultState, enhancers);
};
