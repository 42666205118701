import { POST_MESSAGE_TYPE } from '../../constants';

import orderSelectors from '../selectors/order';
const { getProductList, getConfiguration } = orderSelectors;

// use to postMessage from app to parent site
export const postMessageToParent = (messageType, value) => async (
  dispatch,
  getState
) => {
  const { app } = getState();
  const { parentOrigin, env } = app;
  const message = { messageType };
  switch (messageType) {
    case POST_MESSAGE_TYPE.productListChange:
      message.value = dispatch(getProductList());
      break;
    case POST_MESSAGE_TYPE.getConfiguration:
      message.value = await dispatch(getConfiguration());
      break;
    case POST_MESSAGE_TYPE.addToCart:
      message.value = {
        ...value,
        productList: dispatch(getProductList()),
      };
      break;
    default:
      return console.error(
        `Post message to parent site error！ Unknow message type ${messageType}!`
      );
  }
  env !== 'production' &&
    console.log(
      '%cIframe post message to parent site:',
      'color: green; font-size: 12px',
      message
    );
  window.parent &&
    window.parent.postMessage(JSON.stringify(message), parentOrigin);
};

export default { postMessageToParent };
