import React, { useState, useEffect, Fragment } from 'react';
import { Checkbox } from 'antd';

import Step from '../Items/StepItem';
import Item from '../Items/SelectedItem';
import { SectionHeading, SectionBody, SectionFooter, Title } from '../shared';
import container from '../../containers/stepSelectorContainer';
import { HOLDING } from '../../constants';

const StepSelector = ({
  handleConfirmSelection,
  handleChangeProcedure,
  handleSelectStep,
  getSteps,
  setStep,
  getInstrumentArrangement,
  getSelectedProcedure,
  configInstruments,
  configBlock,
  silhouetteVisible,
  setSilhouetteVisibility,
}) => {
  const procedureName = getSelectedProcedure()
    ? getSelectedProcedure().label
    : null;
  const steps = getSteps();
  const [instruments, setInstrument] = useState(getInstrumentArrangement());

  useEffect(
    () => {
      setInstrument(getInstrumentArrangement());
      return;
    },
    [configInstruments]
  );

  const handleAddInstrument = (idx) => {
    setStep(steps[idx].name);
    handleSelectStep();
  };

  const handleConfirm = () => handleConfirmSelection();

  return (
    <Fragment>
      <SectionHeading title={procedureName}>
        {configBlock.blockData && (
          <Checkbox
            disabled={!configBlock.blockData.silhouette}
            checked={silhouetteVisible}
            onChange={(e) => setSilhouetteVisibility(e.target.checked)}
          >
            Turn On Silhouette
          </Checkbox>
        )}
      </SectionHeading>
      <SectionBody withWrapper>
        <Title>Available steps</Title>
        {steps &&
          steps.map((step, i) => (
            <Step
              key={i}
              title={step.label}
              id={i}
              handleAddInstrument={() => handleAddInstrument(i)}
            >
              {instruments &&
                instruments[step.name] &&
                instruments[step.name].map((instrument, j) => (
                  <Item
                    key={j}
                    idx={j}
                    inHold={instrument.holeShank === HOLDING.shankName}
                    {...instrument}
                  />
                ))}
            </Step>
          ))}
      </SectionBody>
      <SectionFooter
        handleContinue={handleConfirm}
        dislabled={!instruments}
        handleBack={handleChangeProcedure}
        backLabel="Change Procedure"
      />
    </Fragment>
  );
};

export default container(StepSelector);
