import React from 'react';
import { connect } from 'react-redux';
import { selectors } from '../modules';

export default Component => {
  const mapStateToProps = state => ({
    section: state.ui.section,
  });
  const mapDispatchToProps = {
    //  Selectors
    filterBlockLabels: selectors.metadata.getBlockLabels,
    getBlockLabels: selectors.config.filterBlockFromConfigState,
    getFilters: selectors.ui.getBlockFilter,
  };

  const WrappedComponent = props => <Component {...props} />;

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent);
};
