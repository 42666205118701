import React from 'react';
import { connect } from 'react-redux';
import { actions, selectors } from '../modules';

export default (Component) => {
  const mapDispatchToProps = {
    handleConfirmSelection: actions.ui.goToNextSection,
    setSilhouetteVisibility: actions.config.setSilhouetteVisibility,
  };

  const mapStateToProps = ({ config }) => ({
    configInstruments: config.instruments,
    configBlock: config.block,
    silhouetteVisible: config.silhouetteVisible,
  });

  const WrappedComponent = (props) => <Component {...props} />;

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WrappedComponent);
};
