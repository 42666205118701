import React from 'react';
import { Button } from 'antd';
import { Wrapper } from './actionButton.styles';

export default ({
  disabled,
  handleContinue,
  continueLabel = 'Continue',
  handleBack,
  backLabel = 'Back',
  handleAction,
  actionLabel = 'Action',
  actionDisabled,
}) => (
  <Wrapper>
    {handleBack && <Button onClick={handleBack}>{backLabel}</Button>}
    {handleAction && (
      <Button disabled={actionDisabled} onClick={handleAction}>
        {actionLabel}
      </Button>
    )}

    <Button type="primary" disabled={disabled} onClick={handleContinue}>
      {continueLabel}
    </Button>
  </Wrapper>
);
