import React, { useState } from 'react';
import {
  TagWrapper,
  Wrapper,
  Primary,
  Secondary,
  SecondaryText,
  Image,
  Buttons,
  ItemBtn,
} from './items.styles';
import { CenterVert } from '../shared';
import { Icon } from 'antd';
import iconNoImage from '../../assets/icons/no-image.webp';

const Tag = ({ title, disabled }) => (
  <TagWrapper disabled={disabled}>
    <div>{title}</div>
  </TagWrapper>
);

export const Item = ({
  assetId,
  thumbnailUrl,
  name,
  label,
  description,
  productLabel,
  recommended,
  selectable,
  selected,
  onSelect,
  onHover,
  onMouseOut,
  disabled,
  productNumber,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isDisabled = disabled || !assetId;

  const handleClickExpand = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleSelect = ({ currentTarget: e }) =>
    !isDisabled && onSelect(e.getAttribute('name'));

  const handleHover = (e) => {
    if (isDisabled || !onHover) return;
    e.stopPropagation();
    onHover(name);
  };

  const handleMouseOut = (e) => {
    if (isDisabled || !onMouseOut) return;
    e.stopPropagation();
    onMouseOut();
  };
  return (
    <>
      {recommended && <Tag title="Recommended" disabled={isDisabled} />}
      <Wrapper
        expand={isExpanded}
        size="large"
        selected={selected}
        disabled={isDisabled}
        onMouseOver={handleHover}
        onMouseOut={handleMouseOut}
        onClick={handleSelect}
        name={name}
      >
        <Primary size="large">
          <div>
            {thumbnailUrl && (
              <Image expand={isExpanded}>
                <img
                  src={thumbnailUrl}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = iconNoImage;
                  }}
                  alt="item"
                />
              </Image>
            )}
          </div>
          <CenterVert>{label || productLabel}</CenterVert>
          <CenterVert>
            <Buttons>
              <ItemBtn onClick={handleClickExpand} expand={isExpanded}>
                <Icon type="down" />
              </ItemBtn>
              {selectable && (
                <ItemBtn>
                  <Icon type="delete" />
                </ItemBtn>
              )}
            </Buttons>
          </CenterVert>
        </Primary>
        {isExpanded && (
          <Secondary>
            <SecondaryText>{description}</SecondaryText>
            <SecondaryText>{productNumber}</SecondaryText>
          </Secondary>
        )}
      </Wrapper>
    </>
  );
};

export default Item;
