import styled from 'styled-components';

const PADDING = '10px';

export const Wrapper = styled.div`
  /* padding: 30px; */
  width: 100%;
  height: 100%;
  padding: ${PADDING};

  position: relative;
  display: flex;

  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background: white;
`;

export const PlayerWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-right: ${PADDING};
  overflow: hidden;
`;

export const ControllerWrapper = styled.div`
  width: 400px;
  height: 100%;
`;
