import React, { useState } from 'react';
import { Icon, Button } from 'antd';
import {
  StepWrapper as Wrapper,
  StepPrimary as Primary,
  StepSecondary as Secondary,
  StepTitle as Title,
  StepCounter as Counter,
  StepItemButton as StepButton,
  ExpandButton,
} from './items.styles';
import { CenterVert } from '../shared';

export default ({ children, title, handleAddInstrument }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedChildren, setExpandedChildren] = useState(new Set([]));
  const count = children && children.length > 0 ? children.length : null;

  const handleExpand = () => setIsExpanded(!isExpanded);

  const handleExpandChild = (idx) => {
    const updatedExpanded = new Set(expandedChildren);
    expandedChildren.has(idx)
      ? updatedExpanded.delete(idx)
      : updatedExpanded.add(idx);
    setExpandedChildren(updatedExpanded);
  };

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      handleExpand: handleExpandChild,
      expandedList: expandedChildren,
    })
  );
  return (
    <Wrapper size={'small'} expand={isExpanded}>
      <Primary size={'small'}>
        <CenterVert>
          <ExpandButton
            expand={isExpanded}
            show={!!count}
            onClick={handleExpand}
          >
            <Icon type="down" disabled />
          </ExpandButton>
        </CenterVert>
        <Title>
          <CenterVert>{title}</CenterVert>
          <CenterVert>{count && <Counter>{count}</Counter>}</CenterVert>
        </Title>
        <CenterVert>
          <StepButton>
            <Button size="small" onClick={handleAddInstrument}>
              <Icon type="plus" style={{ color: 'gray' }} /> add instruments
            </Button>
          </StepButton>
        </CenterVert>
      </Primary>
      {isExpanded && <Secondary>{childrenWithProps}</Secondary>}
    </Wrapper>
  );
};
