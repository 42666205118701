import styled from 'styled-components';
import {
  SECTION_FOOTER_HEIGHT,
  ACTION_BUTTON_HEIGHT,
  SECTION_MARGIN,
} from '../shared.styles';

export const Wrapper = styled.div`
  height: ${SECTION_FOOTER_HEIGHT}px;
  position: absolute;
  bottom: 0px;
  width: 100%;
`;

export const PriceWrapper = styled.div`
  text-align: right;
  font-size: 16px;
  font-weight: 600;
  color: #999999;
  margin-bottom: ${SECTION_MARGIN}px;

  span {
    font-weight: 400;
  }
`;
