import styled from 'styled-components';
import {
  SECTION_FOOTER_HEIGHT,
  SECTION_MARGIN,
  SMALL_SECTION_HEADER_HEIGHT,
  LARGE_SECTION_HEADER_HEIGHT,
} from '../shared.styles';

const getHeight = (props) => {
  return (
    (props.headerSize === 'large'
      ? LARGE_SECTION_HEADER_HEIGHT
      : SMALL_SECTION_HEADER_HEIGHT) +
    SECTION_FOOTER_HEIGHT +
    SECTION_MARGIN * 2
  );
};

export const Wrapper = styled.div`
  overflow-y: ${(props) => (props.withWrapper ? 'none' : 'auto')};
  scrollbar-width: none;
  margin-bottom: ${SECTION_MARGIN + SECTION_FOOTER_HEIGHT}px;
  height: ${(props) => `calc(100% - ${getHeight(props)}px)`};
`;

export const ChildWrapper = styled.div`
  padding: ${SECTION_MARGIN}px;
  background: #f1f1f1;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
