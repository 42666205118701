import queryString from 'query-string';

import {
  PLATFORM_ASSET_API,
  PLATFORM_FILE_API,
  APP_METADATA_API,
  APP_ASSET_API,
  BASE_ASSET_ID,
  ORG_ID,
  PROD_ORG_ID,
  AUTH_TOKEN,
  PRODUCT_SHEET_ID,
  LOGIC_SHEET_ID,
} from '../../constants';

const fetchSpreadsheetData = async () => {
  const search = queryString.parse(window.location.search);

  const queryParamters = [];
  queryParamters.push(
    `productSheetId=${
      search.productSheetId ? search.productSheetId : PRODUCT_SHEET_ID
    }`
  );
  queryParamters.push(
    `logicSheetId=${search.logicSheetId ? search.logicSheetId : LOGIC_SHEET_ID}`
  );

  let query = queryParamters.join('&');
  if (query) query = '/?' + query;
  const result = await fetch(`${APP_METADATA_API}${query}`).then((res) =>
    res.json()
  );
  if (result.error) throw new Error(result.error);

  return result;
};

const fetchProdMetadata = async () => {
  const { description: fileId } = await fetch(
    `${PLATFORM_ASSET_API}/${BASE_ASSET_ID}?orgId=${ORG_ID}&bearer_token=${AUTH_TOKEN}`
  ).then((res) => res.json());

  return fetch(`${PLATFORM_FILE_API}/${fileId}/content`).then((res) =>
    res.json()
  );
};

export const fetchMetadata = () =>
  process.env.REACT_APP_ENV === 'PROD'
    ? fetchProdMetadata()
    : fetchSpreadsheetData();

export const saveSpreadsheetDataToPlatform = async () => {
  const result = await fetch(`${APP_METADATA_API}/save`).then((res) =>
    res.json()
  );
  if (result.error) throw new Error(result.error);

  return result;
};

export const saveFileIdToDevAsset = async (migrationData) =>
  saveFileIdToAsset(migrationData, 'dev');
export const saveFileIdToProdAsset = async (migrationData) =>
  saveFileIdToAsset(migrationData, 'prod');

const saveFileIdToAsset = async (migrationData, env) => {
  const { id } = migrationData[0];
  const result = await fetch(`${APP_ASSET_API}`, {
    method: 'POST',
    body: JSON.stringify({
      assetId: BASE_ASSET_ID,
      env,
      orgId: env === 'dev' ? ORG_ID : PROD_ORG_ID,
      fileId: id,
    }),
  }).then((res) => res.json());
  if (result.error) throw new Error(result.error);
  return result;
};

export const migrateAssetToProd = async (migrationData) => {
  const result = await fetch(`${APP_ASSET_API}/migrate`, {
    method: 'POST',
    body: JSON.stringify({ sourceOrg: ORG_ID, targetOrg: PROD_ORG_ID }),
  }).then((res) => res.json());

  if (result.error) throw new Error(result.error);

  return result;
};
