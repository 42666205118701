import React from 'react';
import { connect } from 'react-redux';
import { actions, selectors } from '../modules';

export default (Component) => {
  const mapDispatchToProps = {
    //  selectors
    getSelectedStep: selectors.ui.getSelectedStep,
    getInstrumentArrangement: selectors.ui.getInstrumentArrangement,
    getSelectedProcedure: selectors.ui.getSelectedProcedure,
    getSteps: selectors.metadata.getProcedureStepsByProcedure,
    //  actions
    setStep: actions.ui.setStep,
    handleConfirmSelection: actions.ui.goToNextSection,
    setSilhouetteVisibility: actions.config.setSilhouetteVisibility,
  };

  const mapStateToProps = ({ config }) => ({
    configInstruments: config.instruments,
    configBlock: config.block,
    silhouetteVisible: config.silhouetteVisible,
  });

  const WrappedComponent = (props) => <Component {...props} />;

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WrappedComponent);
};
