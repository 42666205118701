import {
  SET_INIT_STATE,
  SET_PROCEDURE,
  SET_SECTION,
  SET_STEP,
} from './utils/actionType';
import { UI_FLOW, UI_SECTIONS } from '../constants';

export const initialState = {
  flow: UI_FLOW.blank,
  section: UI_SECTIONS[UI_FLOW.blank][0],
  selectedProcedure: undefined,
  selectedStep: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_INIT_STATE:
      return { ...state, ...(payload.view || {}) };
    case SET_SECTION:
      return { ...state, section: payload };
    case SET_PROCEDURE:
      return { ...state, selectedProcedure: payload };
    case SET_STEP:
      return { ...state, selectedStep: payload };
    default:
      return state;
  }
};
