// global action
export const SET_INIT_STATE = 'SET_INIT_STATE';

// app module
export const SET_APP_HEIGHT = 'SET_APP_HEIGHT';
// config module
export const SET_BLOCK = 'SET_BLOCK';
export const SET_INSTRUMENTS = 'SET_INSTRUMENTS';
export const ADD_INSTRUMENTS = 'ADD_INSTRUMENTS';
export const DELETE_INSTRUMENTS = 'DELETE_INSTRUMENTS';
export const SET_SELECTED_MODEL = 'SET_SELECTED_MODEL';
export const SET_HOLDING = 'SET_HOLDING';
export const SET_LID_ANIMATION = 'SET_LID_ANIMATION';
export const SET_SILHOUETTE_VISIBLE = 'SET_SILHOUETTE_VISIBLE';
export const SET_LOGO = 'SET_LOGO';
export const SET_BLOCK_NAME = 'SET_BLOCK_NAME';

// ui module
export const SET_SECTION = 'SET_SECTION';
export const SET_PROCEDURE = 'SET_PROCEDURE';
export const SET_STEP = 'SET_STEP';

// order module
export const SET_PRICE = 'SET_PRICE';
export const SET_SUBMIT_STATUS = 'SET_SUBMIT_STATUS';
export const SET_SAVED_ID = 'SET_SAVED_ID';
export const SET_UUID = 'SET_UUID';
