import React, { useState, useEffect, Fragment } from 'react';
import { SectionHeading, SectionBody, SectionFooter } from '../shared';
import ProcedureItem from '../Items/ProcedureItem';
import container from '../../containers/procedureSelectorContainer';

export const ProcedureSelector = ({
  getProcedures,
  setProcedure,
  selectedProcedure,
  handleSelectProcedure,
}) => {
  const procedures = getProcedures();
  const [preselected, setPreselected] = useState(selectedProcedure);
  useEffect(
    () => {
      setPreselected(selectedProcedure);
    },
    [selectedProcedure]
  );
  const handlePreselect = (procedureName) =>
    preselected === procedureName
      ? setPreselected(null)
      : setPreselected(procedureName);

  const handleConfirmSelection = () => {
    setProcedure(preselected);
    handleSelectProcedure();
  };

  return (
    <Fragment>
      <SectionHeading title="Select Procedure" />
      <SectionBody withWrapper>
        {procedures &&
          procedures.map(({ name, label, description }, i) => (
            <ProcedureItem
              title={label}
              description={description}
              key={i}
              id={name}
              onClick={handlePreselect}
              selected={name === preselected}
            />
          ))}
      </SectionBody>
      <SectionFooter
        continueDisabled={!preselected}
        handleContinue={handleConfirmSelection}
        continueLabel="Confirm Selection"
      />
    </Fragment>
  );
};

export default container(ProcedureSelector);
