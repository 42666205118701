import { SET_INIT_STATE, SET_APP_HEIGHT } from './utils/actionType';

export const initialState = {
  loaded: false,
  env: undefined,
  rollbar: null,
  parentOrigin: '*',
  appHeight: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_INIT_STATE:
      return { ...state, ...(payload.app || {}) };
    case SET_APP_HEIGHT:
      return { ...state, appHeight: payload };
    default:
      return state;
  }
};
